import { Link } from "@chakra-ui/layout";
import React, { useState } from "react";
import MyMainHeading from "../../app/components/Text/MyMainHeading";
import MyParagraph from "../../app/components/Text/MyParagraph";
import MySubHeading from "../../app/components/Text/MySubHeading";
import TextWallContainer from "../../app/components/Text/TextWallContainer";
import Vcontainer from "../../app/components/Vcontainer/Vcontainer";

export default function AboutEmail(props) {
  const [email, setEmail] = useState("(Reveil eMail)");

  return (
    <Vcontainer maxW='960px' margin='auto' textAlign='center'>
      <TextWallContainer>
        <MyMainHeading>Contact</MyMainHeading>
        <MySubHeading mt='30px' size='md'>
          eMail
        </MySubHeading>
        <MyParagraph>
          Feel free to send me an&#160;
          {email !== "(Reveil eMail)" && (
            <>
              email to <b>{email}</b>
            </>
          )}
          {email === "(Reveil eMail)" && (
            <>
              email to{" "}
              <Link
                onClick={() => {
                  const part1 = "thefly";
                  const part2 = Math.pow(2, 6);
                  const part3 = String.fromCharCode(part2);
                  const part4 = "streamteam.gg";
                  setEmail(part1 + part3 + part4);
                }}
              >
                {email}
              </Link>
            </>
          )}
        </MyParagraph>
      </TextWallContainer>
    </Vcontainer>
  );
}
