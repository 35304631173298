import { toast } from "react-toastify";
import firebase from "../config/firebase";
import {
  setUserProfileData,
  setUserProfileDataGoogle,
  setUserProfileDataTwitch,
} from "./firestoreService";

export function firebaseObjectToArray(snapshot) {
  if (snapshot) {
    return Object.entries(snapshot).map((e) =>
      Object.assign({}, e[1], { id: e[0] })
    );
  }
}

export function signInWithEmail(creds) {
  return firebase
    .auth()
    .signInWithEmailAndPassword(creds.email, creds.password);
}

export function resetPassword(creds) {
  return firebase.auth().sendPasswordResetEmail(creds.email);
}

export function signOutFirebase() {
  return firebase.auth().signOut();
}

export async function getUserAuthToken() {
  // const token = await firebase.auth().currentUser.getIdToken(true);
  const tokenResult = await firebase.auth().currentUser.getIdTokenResult(true);

  // console.log("authTime", tokenResult.authTime);
  // console.log("issuedAtTime", tokenResult.issuedAtTime);
  // console.log("expirationTime", tokenResult.expirationTime);
  // console.log("token", tokenResult.token);
  // console.log("signInProvider", tokenResult.signInProvider);

  return tokenResult.token;
}

export async function registerInFirebase(creds, laststreamerUid) {
  try {
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(creds.email, creds.password);
    await result.user.updateProfile({ displayName: creds.displayName });
    return await setUserProfileData(result.user);
  } catch (error) {
    throw error;
  }
}

export async function socialUnlink(selectedProvider) {
  const user = firebase.auth().currentUser;

  return user.unlink(selectedProvider);
}

export async function socialLinkPassword(values) {
  const user = firebase.auth().currentUser;
  var credential = firebase.auth.EmailAuthProvider.credential(
    values.email,
    values.password
  );
  return user.linkWithCredential(credential);
  // .then((usercred) => {
  //   var user = usercred.user;
  //   console.log("Account linking success", user);
  // })
  // .catch((error) => {
  //   console.log("Account linking error", error);
  // });
}

export async function socialLink(selectedProvider) {
  const user = firebase.auth().currentUser;
  let provider;

  if (selectedProvider === "twitch") {
    provider = new firebase.auth.OAuthProvider("oidc.twitch.tv");
    provider.addScope("user:read:email");
    // provider.addScope("channel:read:subscriptions");
    // provider.addScope("user:read:subscriptions");
    provider.addScope("openid");
    provider.setCustomParameters({
      claims: JSON.stringify({
        id_token: {
          preferred_username: null,
          email: null,
          email_verified: null,
          picture: null,
        },
      }),
    });
  }
  if (selectedProvider === "discord") {
    provider = new firebase.auth.OAuthProvider("oidc.discord");
  }
  if (selectedProvider === "google") {
    provider = new firebase.auth.GoogleAuthProvider();
  }

  return user.linkWithPopup(provider);
}

export async function socialLogin(selectedProvider) {
  let provider;
  if (selectedProvider === "twitch") {
    // OIDC Google Identity Platform. Using a popup.
    provider = new firebase.auth.OAuthProvider("oidc.twitch.tv");
    provider.addScope("user:read:email");
    // provider.addScope("channel:read:subscriptions");
    // provider.addScope("user:read:subscriptions");
    provider.addScope("openid");
    provider.setCustomParameters({
      claims: JSON.stringify({
        id_token: {
          preferred_username: null,
          email: null,
          email_verified: null,
          picture: null,
        },
      }),
    });

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // var accessToken = result.credential.accessToken;
        // console.log(accessToken);

        if (result.additionalUserInfo.isNewUser) {
          setUserProfileDataTwitch(
            result.user,
            result.additionalUserInfo.profile
          );
        }
      });
  } else if (selectedProvider === "discord") {
    // OIDC Google Identity Platform. Using a popup.
    provider = new firebase.auth.OAuthProvider("oidc.discord");
    provider.addScope("identify");
    // provider.addScope("email");

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // console.log(result);
        // if (result.additionalUserInfo.isNewUser) {
        //   setUserProfileDataDiscord(
        //     result.user,
        //     result.additionalUserInfo.profile
        //   );
        // }
      });
  } else {
    if (selectedProvider === "google") {
      provider = new firebase.auth.GoogleAuthProvider();
    }

    try {
      let result = await firebase.auth().signInWithPopup(provider);

      if (result.additionalUserInfo.isNewUser) {
        await setUserProfileDataGoogle(result.user);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
}

export function updateUserEmail(creds) {
  const user = firebase.auth().currentUser;
  return user.updateEmail(creds.email);
}

export function updateUserPassword(creds) {
  const user = firebase.auth().currentUser;
  return user.updatePassword(creds.newPassword1);
}

export function uploadToFirebaseStorage(file, filename) {
  const user = firebase.auth().currentUser;
  const storageRef = firebase.storage().ref();
  return storageRef.child(`${user.uid}/user_images/${filename}`).put(file);
}

export function deleteFromFirebaseStorage(filename) {
  const userUid = firebase.auth().currentUser.uid;
  const storageRef = firebase.storage().ref();
  const photoRef = storageRef.child(`${userUid}/user_images/${filename}`);
  return photoRef.delete();
}

export function addEventChatComment(eventId, values) {
  const user = firebase.auth().currentUser;
  const newComment = {
    displayName: user.displayName,
    photoURL: user.photoURL,
    uid: user.uid,
    text: values.comment,
    date: Date.now(),
    parentId: values.parentId,
  };
  return firebase.database().ref(`chat/${eventId}`).push(newComment);
}

export function getEventChatRef(eventId) {
  return firebase.database().ref(`chat/${eventId}`).orderByKey();
}

export function getUserFeedRef() {
  const user = firebase.auth().currentUser;
  return firebase
    .database()
    .ref(`posts/${user.uid}`)
    .orderByKey()
    .limitToLast(5);
}

export function deleteLobbyFromFirebase(lobbyId) {
  return firebase.database().ref(`lobby/${lobbyId}`).remove();
}

export function addLobbyToFirebase(values) {
  const user = firebase.auth().currentUser;
  const newValues = {
    ...values,
    status: "open",
    streamerId: user.uid,
    created: Date.now(),
    gameinprogress: "",
  };
  // console.log(newValues);
  return firebase.database().ref(`lobby`).push(newValues);
}

export async function updateLobbyInFirebase(lobbyId, values) {
  if (!lobbyId || lobbyId === undefined) return;
  return firebase.database().ref(`lobby/${lobbyId}`).update(values);
}

export async function addLobbyQuizUserAnswer({ lobbyId, question, answer }) {
  const user = firebase.auth().currentUser;

  if (!lobbyId || lobbyId === undefined) return;
  const quizvalues = {
    answer: answer,
    created: Date.now(),
  };
  return firebase
    .database()
    .ref(`quiz/${lobbyId}/answers/${question}/${user.uid}/`)
    .set(quizvalues);
}

export async function endLobbyQuizInFirebase(lobbyId) {
  if (!lobbyId || lobbyId === undefined) return;
  return firebase
    .database()
    .ref(`lobby/${lobbyId}/quiz`)
    .update({ status: "finished" });
}

export async function removeLobbyQuizInFirebase(lobbyId) {
  if (!lobbyId || lobbyId === undefined) return;
  return firebase.database().ref(`lobby/${lobbyId}/quiz`).set({});
}

export async function setLobbyQuizUsersCanAnswerFirebase({
  lobbyId,
  usersCanAnswer,
}) {
  if (!lobbyId || lobbyId === undefined) return;

  return firebase
    .database()
    .ref(`lobby/${lobbyId}/quiz/currentQuestion`)
    .update({
      usersCanAnswer: usersCanAnswer,
    });
}

export async function updateLobbyQuizInFirebase({ lobbyId, currentQuestion }) {
  if (!lobbyId || lobbyId === undefined) return;

  const quizvalues = {
    updated: Date.now(),
    currentQuestion: currentQuestion,
  };

  return firebase.database().ref(`lobby/${lobbyId}/quiz`).update(quizvalues);
}

export async function createLobbyQuizInFirebase(lobbyId, values) {
  if (!lobbyId || lobbyId === undefined) return;

  firebase
    .database()
    .ref(`quiz/${lobbyId}`)
    .set({ answers: {}, questions: values });

  const quizvalues = {
    status: "in_progress",
    created: Date.now(),
    updated: Date.now(),
    currentQuestion: { index: -1, maxIndex: values.length - 1 },
  };
  return firebase.database().ref(`lobby/${lobbyId}/quiz`).set(quizvalues);
}

export async function updateLobbySelectedUsersBulkInFirebase(lobbyId, teams) {
  if (!lobbyId || lobbyId === undefined) return;
  if (!teams || teams === undefined) return;

  let newData = {};

  teams.map((team, tindex) => {
    team.map((user, sindex) => {
      newData[`selectedUsers/${tindex}/${sindex}`] = user;
      if (user.userId !== "" && !(tindex === 0 && sindex === 0))
        newData[`users/${user.id}/status`] = "selected";
      return true;
    });
    return true;
  });

  return firebase.database().ref(`lobby/${lobbyId}`).update(newData);
}

export async function updateLobbySelectedUsersInFirebase(
  lobbyId,
  teamNum,
  slotNum,
  user
) {
  if (!lobbyId || lobbyId === undefined) return;
  if (!teamNum || teamNum === undefined) return;
  if (!slotNum || slotNum === undefined) return;

  let newData = {};
  newData[`selectedUsers/${teamNum}/${slotNum}`] = user;
  newData[`users/${user.id}/status`] = "selected";

  return firebase.database().ref(`lobby/${lobbyId}`).update(newData);
}

export async function updateLobbyUserStatusInFirebase(lobbyId, userId, status) {
  if (!lobbyId || lobbyId === undefined) return;
  if (!userId || userId === undefined) return;

  let updates = {};
  updates["status"] = status;

  return firebase
    .database()
    .ref(`lobby/${lobbyId}/users/${userId}`)
    .update(updates);
}

export async function updateLobbyUsersBulkStatusInFirebase(
  lobbyId,
  users,
  status
) {
  if (!lobbyId || lobbyId === undefined) return;
  if (!users || users === undefined) return;
  let updates = {};

  users.map((doc) => {
    updates["users/" + doc.id + "/status"] = status;
    return true;
  });

  return firebase.database().ref(`lobby/${lobbyId}`).update(updates);
}

export function updateLobbyAllUserStatusInFirebase(lobbyId, status) {
  if (!lobbyId || lobbyId === undefined) return;
  try {
    let updates = {};
    // updates["status"] = "in_progress";
    firebase
      .database()
      .ref(`lobby/${lobbyId}`)
      .once("value")
      .then((snapshot) => {
        const lobby = snapshot.val();
        const users = firebaseObjectToArray(lobby.users);
        const selectedUsers = firebaseObjectToArray(lobby.selectedUsers);

        selectedUsers.map((team) => {
          Object.entries(team).map(([slotkey, slot]) => {
            if (parseInt(team.id) === 0 && parseInt(slotkey) === 0) {
              // is streamer / host, do nothing
            } else {
              if (slotkey !== "id") {
                updates["selectedUsers/" + team.id + "/" + slotkey] = {
                  userId: "",
                  displayName: "",
                };
              }
            }
            return true;
          });
          return true;
        });

        users?.map((doc) => {
          updates["users/" + doc.id + "/status"] = status;
          return true;
        });
      })
      .then(() => {
        // console.log(updates);
        firebase.database().ref(`lobby/${lobbyId}`).update(updates);
      });
  } catch (error) {
    throw error;
  }
}

export async function getLobbyFromFirebase(lobbyId) {
  const dbRef = firebase.database().ref(`lobby/${lobbyId}`);
  let data;
  await dbRef
    .get()
    .then((snapshot) => {
      if (snapshot.exists()) {
        data = snapshot.val();
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });

  return data;
}

export function listenToQuizUserAnswerFromFirebase(
  lobbyId,
  questionId,
  userId
) {
  return firebase
    .database()
    .ref(`quiz/${lobbyId}/answers/${questionId}/${userId}/`);
}

export function listenToQuizFromFirebase(lobbyId) {
  return firebase.database().ref(`quiz/${lobbyId}`);
}

export function listenToLobbyFromFirebase(lobbyId) {
  return firebase.database().ref(`lobby/${lobbyId}`);
}

export function joinLobbyInFirebase(lobbyId, userProfile, stats) {
  const lobbyRef = firebase.database().ref(`lobby/${lobbyId}`);

  let numUsersInQueue;
  let maxQueueSize;

  lobbyRef.once("value", (data) => {
    maxQueueSize = data.val().maxQueueSize
      ? data.val().maxQueueSize
      : process.env.REACT_APP_MAXQUEUESIZE_DEFAULT;

    numUsersInQueue = data.val().users
      ? Object.keys(data.val().users).length
      : 0;
  });

  if (numUsersInQueue >= maxQueueSize)
    throw new Error("Maxiumum number of users have joined this lobby.");

  console.log(stats);

  const values = {
    joined: Date.now(),
    userId: userProfile.id,
    displayName: userProfile.displayName,
    status: "joined",
    photoURL: userProfile.photoURL,
    hometown: userProfile.hometown ? userProfile.hometown : null,
    accounts: userProfile.accounts ? userProfile.accounts : null,
    accountsKeys: userProfile.accountsKeys ? userProfile.accountsKeys : null,
    stats: { ...stats, firstJoin: Date.parse(stats.firstJoin) },
  };
  return firebase.database().ref(`lobby/${lobbyId}/users`).push(values);
}

export function leaveLobbyInFirebase(lobbyId, userLobbyId) {
  if (!userLobbyId) return;

  return firebase
    .database()
    .ref(`lobby/${lobbyId}/users/${userLobbyId}`)
    .remove();
}
