import { Form, Formik } from "formik";
import React from "react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import * as Yup from "yup";
import { addUserAccountToFirestore } from "../../../app/firestore/firestoreService";
import { FormControl } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/react";
import { HStack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";

export default function AccountFormEdit({
  currentUserProfile,
  account,
  accountTypes,
  closeEdit,
}) {
  const toast = useToast();
  // const [field, meta] = useField(account);
  // const checkregex = accountTypes.filter((a) => a.key === account.type)[0]
  //   .checkregex;

  function handleCancel() {
    closeEdit("");
  }

  // function escapeRegExp(stringToGoIntoTheRegex) {
  //   return stringToGoIntoTheRegex.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  // }

  if (!account.type) return <></>;

  return (
    <Formik
      key={`${account.type}_formik`}
      enableReinitialize
      initialValues={{
        type: account.type,
        value: account.value,
      }}
      validationSchema={Yup.object({
        type: Yup.string().max(20).required(),
        value: Yup.string()
          .max(30)
          .trim()
          // .test("test-name", "Validation failure message", function (value) {
          //   // let re = /^.{3,32}#[0-9]{4}$/g;
          //   let re = new RegExp("/^.{3,32}#[0-9]{4}$/", "g");
          //   let found = value.match(re);
          //   return found;
          // })

          // .matches(
          //   new RegExp("/" + escapeRegExp("^.{3,32}#[0-9]{4}$") + "/", "g"),
          //   "Is not in correct format"
          // )
          // .matches(`/${checkregex}/`, "Is not in correct format")
          .required(),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await addUserAccountToFirestore(currentUserProfile, values);
          closeEdit();
          toast({
            title: "Success",
            description: "Account has been saved",
            status: "success",
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            isClosable: true,
          });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form key={`${account.type}_form`}>
          <FormControl>
            <HStack>
              <MyTextInput
                autoFocus='autoFocus'
                padding='5px'
                name='value'
                m='0'
              />
              <input type='hidden' name='type' />
              <Button
                isLoading={isSubmitting}
                isDisabled={isSubmitting || !isValid}
                type='submit'
                size='sm'
                variant='primary'
              >
                Save
              </Button>
              <Button size='sm' onClick={() => handleCancel()}>
                Cancel
              </Button>
            </HStack>
          </FormControl>
        </Form>
      )}
    </Formik>
  );
}
