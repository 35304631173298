import React, { useState } from "react";
import * as Yup from "yup";
import {
  addQuizItem,
  updateQuizItem,
} from "../../../app/firestore/firestoreService";
import { Box, HStack, Wrap, WrapItem } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { Form, Formik } from "formik";
import { FormControl } from "@chakra-ui/form-control";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { Button } from "@chakra-ui/react";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../app/common/modals/modalReducer";
import {
  asyncActionFinish,
  asyncActionStart,
} from "../../../app/async/asyncReducer";

import { GiCrossMark } from "react-icons/gi";
import { BiCircle } from "react-icons/bi";
import MySelectInput from "../../../app/common/form/MySelectInput";

export default function StreamerSettingsQuizEditForm({
  currentUserProfile,
  item,
  answers,
  closeEdit,
  categories,
}) {
  const toast = useToast();
  const dispatch = useDispatch();

  const [correctAnswer, setCorrectAnswer] = useState(item?.correctAnswer);
  // const checkregex = accountTypes.filter((a) => a.key === account.type)[0]
  //   .checkregex;

  function handleCancel() {
    dispatch(closeModal());
  }

  // function escapeRegExp(stringToGoIntoTheRegex) {
  //   return stringToGoIntoTheRegex.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  // }

  const existingCategories = [];

  console.log(categories);
  if (categories) {
    Object.keys(categories).forEach(function (key) {
      existingCategories.push({ key: key, text: key, value: key });
    });
  }

  return (
    <ModalWrapper
      size='xl'
      header={item ? "Edit Quiz Question" : "Add Quiz Question"}
    >
      <Formik
        enableReinitialize
        initialValues={{
          question: item?.question || "",
          answer1: item?.answer1 || "",
          answer2: item?.answer2 || "",
          answer3: item?.answer3 || "",
          answer4: item?.answer4 || "",
          category: item?.category || "",
          correctAnswer: item?.correctAnswer || 0,
        }}
        validationSchema={Yup.object({
          question: Yup.string().max(60).trim().required(),
          answer1: Yup.string().max(40).trim().required(),
          answer2: Yup.string().max(40).trim().required(),
          answer3: Yup.string().max(40).trim().required(),
          answer4: Yup.string().max(40).trim().required(),
          category: Yup.string().max(30).trim(),
          correctAnswer: Yup.number().required().positive().integer(),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          let valuessave = values;
          if (values.newCategory) {
            valuessave = {
              ...values,
              category: values.newCategory,
            };
            delete valuessave.newCategory;
          }
          try {
            dispatch(asyncActionStart());
            if (item) {
              await updateQuizItem(item.id, valuessave);
            } else {
              await addQuizItem(valuessave);
            }
            dispatch(closeModal());
            dispatch(asyncActionFinish());
            resetForm();
            toast({
              title: "Success",
              description: "Quiz Item has been saved",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Error",
              description: error.message,
              status: "error",
              isClosable: true,
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          isSubmitting,
          values,
          isValid,
          dirty,
          resetForm,
          setFieldValue,
        }) => (
          <Form key='quiz_item_edit'>
            <FormControl>
              <MyTextInput
                mt='35px'
                flex='1'
                name='question'
                label='Question'
              />

              <Wrap>
                {answers.map((e) => (
                  <WrapItem
                    key={`answer${e}`}
                    w={["100%", "100%", "48%", "48%", "48%"]}
                  >
                    <Box
                      position='relative'
                      style={{
                        fontSize: "30px",
                        marginRight: "10px",
                        marginTop: "30px",
                        cursor: "pointer",
                      }}
                    >
                      {correctAnswer === e && (
                        <Box
                          color={"primary"}
                          position='absolute'
                          top='0px'
                          left='0px'
                        >
                          <GiCrossMark />
                        </Box>
                      )}

                      <Box>
                        <BiCircle
                          onClick={() => {
                            setCorrectAnswer(e);
                            setFieldValue("correctAnswer", e);
                          }}
                        />
                      </Box>
                    </Box>

                    <MyTextInput
                      mt='35px'
                      flex='1'
                      name={`answer${e}`}
                      label={`Answer ${e}`}
                    />
                  </WrapItem>
                ))}
              </Wrap>
              <Box mt='30px'>
                <HStack>
                  <Box mr='40px'>
                    <MySelectInput
                      minWidth='180px'
                      name='category'
                      label='Category'
                      options={existingCategories}
                    />
                  </Box>
                  <Box>
                    <MyTextInput name='newCategory' label='Or New Category' />
                  </Box>
                </HStack>
              </Box>
              <Box h='30px' color='primary'>
                {values.answer1 &&
                  values.answer2 &&
                  values.answer3 &&
                  values.answer4 &&
                  !values.correctAnswer && (
                    <>Please flag which answer is the correct one.</>
                  )}
              </Box>
              <HStack mt='40px'>
                <Button
                  isLoading={isSubmitting}
                  // isDisabled={isSubmitting || !isValid}
                  type='submit'
                  variant='primary'
                >
                  Save
                </Button>
                <Button onClick={() => handleCancel()}>Cancel</Button>
              </HStack>
            </FormControl>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
