import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import ProgressbarTime from "../../../app/components/Progressbar/ProgressbarTime";
import { addLobbyQuizUserAnswer } from "../../../app/firestore/firebaseService";

export default function QuizUserAnswerForm({
  lobbyId,
  currentUserProfile,
  question,
  streamerProfile,
}) {
  // const dispatch = useDispatch();
  const [lastQuestionId, setLastQuestionId] = useState(undefined);
  const [answerLocked, setAnswerLocked] = useState("");
  const { quizUserAnswer } = useSelector((state) => state.quiz);
  const timeToAnswer = streamerProfile.quizAnswerDuration || 5; // seconds
  const usersCanAnswer = question.usersCanAnswer;

  console.log(quizUserAnswer);
  useEffect(() => {
    if (question.id !== lastQuestionId) {
      setTimeout(() => {
        setLastQuestionId(question.id);
      }, timeToAnswer * 1000);
    }

    if (question.id !== answerLocked) {
      setAnswerLocked("");
    }
  }, [question, answerLocked, timeToAnswer, lastQuestionId]);

  async function handleClick(answer) {
    if (!usersCanAnswer) return;
    addLobbyQuizUserAnswer({
      lobbyId: lobbyId,
      question: question.id,
      answer: answer,
    });
    setAnswerLocked(question.id);
    // dispatch(closeModal());
  }

  function AnswerBox({ answer, children }) {
    return (
      <Box
        height='150px'
        bg={usersCanAnswer ? "gray.500" : "gray.800"}
        cursor={usersCanAnswer ? "pointer" : "inherit"}
        _hover={{
          backgroundColor: usersCanAnswer ? "gray.600" : "gray.800",
        }}
        textAlign='center'
        m='auto'
        display='inline-block'
        p='0px'
        w='100%'
        borderWidth='3px'
        borderColor={
          quizUserAnswer?.answer === answer &&
          quizUserAnswer?.questionId === question.id
            ? "teal.300"
            : "transparent"
        }
        borderRadius='10px'
        position='relative'
        onClick={() => handleClick(answer)}
      >
        <Box
          m='auto'
          position='absolute'
          top='50%'
          left='50%'
          transform='translate(-50%, -50%)'
          color='white'
          fontSize='lg'
        >
          {children}
          {/* {quizUserAnswer.answer === answer && <Box>x</Box>} */}
        </Box>
      </Box>
    );
  }

  return (
    <ModalWrapper size='4xl' noClose={true} minHeight='100vh'>
      <Text>Quiz - Answer the questions to be part of the streamteam!</Text>
      {question.index === -1 && (
        <Heading mt='50px' size='lg'>
          Prepare for the first question!
        </Heading>
      )}
      {question.index >= 0 && (
        <>
          <Box textAlign='right'>
            Question {question.index + 1} of {question.maxIndex + 1}
          </Box>
          <Heading size='xl' minHeight='86px'>
            {question.question}
          </Heading>
          <Box height='30px' pt='20px' display='block'>
            {usersCanAnswer && question.id !== lastQuestionId && (
              <ProgressbarTime
                height='10px'
                inverted
                duration={timeToAnswer - 1}
              />
            )}
          </Box>
          <SimpleGrid columns={2} spacing={"20px"} mt='30px'>
            <AnswerBox answer={1}>{question.answer1}</AnswerBox>
            <AnswerBox answer={2}>{question.answer2}</AnswerBox>
            <AnswerBox answer={3}>{question.answer3}</AnswerBox>
            <AnswerBox answer={4}>{question.answer4}</AnswerBox>
          </SimpleGrid>
        </>
      )}
    </ModalWrapper>
  );
}
