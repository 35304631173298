import React from "react";
import {
  Box,
  HStack,
  useColorModeValue,
  VStack,
  WrapItem,
} from "@chakra-ui/react";
import MyAvatar from "../../../app/components/MyAvatar/MyAvatar";
import { format } from "date-fns";

export default function UserItem({ user, selectedType, usersReveiled }) {
  const bg = useColorModeValue("white", "gray.900");

  function showStatsNumber(user) {
    switch (selectedType) {
      case "numJoins":
        return user.stats?.numJoins ? user.stats?.numJoins : 0;
      case "numNotSelected":
        return user.stats?.numNotSelected ? user.stats?.numNotSelected : 0;
      case "numNotSelectedStreak":
        return user.stats?.numNotSelectedStreak
          ? user.stats?.numNotSelectedStreak
          : 0;
      case "numSelected":
        return user.stats?.numSelected ? user.stats?.numSelected : 0;
      case "numSelectedStreak":
        return user.stats?.numSelectedStreak
          ? user.stats?.numSelectedStreak
          : 0;
      case "firstJoin":
        return user.stats.firstJoin
          ? format(user.stats.firstJoin, "dd MMM yyyy")
          : "?";
      default:
        return "";
    }
  }

  return (
    <WrapItem
      width={"260px"}
      key={user.id}
      user={user}
      borderRadius={"10px"}
      p={"5px"}
      fadeInUsers={true}
      bg={bg}
    >
      <VStack w={"100%"}>
        <HStack w={"100%"}>
          {/* <Box>{index + 1}.</Box> */}
          <MyAvatar
            name={usersReveiled ? user.displayName : ""}
            src={usersReveiled ? user.photoURL : ""}
            size='xs'
          />
          <Box
            overflow={"hidden"}
            whiteSpace={"nowrap"}
            textOverflow={"ellipsis"}
            width={"120px"}
          >
            {usersReveiled ? user.displayName : "???"}
          </Box>
          <Box
            color={"primary"}
            display='block'
            w={"110px"}
            textAlign='right'
            pr='3px'
          >
            {showStatsNumber(user)}
          </Box>
        </HStack>
      </VStack>
    </WrapItem>
  );
}
