import React from "react";
import { createRoot } from "react-dom/client";
import "react-toastify/dist/ReactToastify.min.css";
import "react-calendar/dist/Calendar.css";
import "./app/layout/styles.css";
import App from "./app/layout/App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Provider } from "react-redux";
import { configureStore, history } from "./app/store/configureStore";
import ScrollToTop from "./app/layout/ScrollToTop";
import { ReduxRouter } from "@lagunovsky/redux-react-router";
import { ChakraProvider } from "@chakra-ui/react";
import { myNewTheme } from "./styles/theme";
import { ColorModeScript } from "@chakra-ui/react";
import { createStandaloneToast } from "@chakra-ui/toast";

import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";

// import "@fontsource/inter/400.css";
// import "@fontsource/inter/600.css";
// import "@fontsource/inter/700.css";

import "@fontsource/rajdhani/400.css";
import "@fontsource/rajdhani/600.css";
import "@fontsource/rajdhani/700.css";

const store = configureStore();
const rootEl = document.getElementById("root");
const root = createRoot(rootEl);
const { ToastContainer } = createStandaloneToast();

function render() {
  root.render(
    <>
      <ColorModeScript initialColorMode={myNewTheme.config.initialColorMode} />
      <Provider store={store}>
        {/* <BrowserRouter> */}
        <ReduxRouter history={history}>
          <ToastContainer />
          <ChakraProvider resetCSS theme={myNewTheme}>
            <ScrollToTop />
            <App />
          </ChakraProvider>
        </ReduxRouter>
        {/* </BrowserRouter> */}
      </Provider>
    </>
  );
}

if (module.hot) {
  module.hot.accept("./app/layout/App", function () {
    setTimeout(render);
  });
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
