import { Form, Formik } from "formik";
import React from "react";

import MyTextInput from "../../../app/common/form/MyTextInput";
import * as Yup from "yup";
import {
  getStreamerUidByURL,
  updateUserProfile,
} from "../../../app/firestore/firestoreService";
import { FormControl } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/react";
import { Box, Text, HStack, Stack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { HashLink } from "react-router-hash-link";

export default function StreamerSettingsMainTab({ currentUserProfile }) {
  const toast = useToast();

  function handleToggleIsStreamer() {
    const toggledIsStreamer = !currentUserProfile.isStreamer;

    if (currentUserProfile.createdLobby) {
      toast({
        title: "ERORR",
        description: "You have an open lobby, close it first!",
        status: "error",
        isClosable: true,
      });
      return;
    }
    let newurl;
    if (toggledIsStreamer) {
      newurl =
        currentUserProfile.displayName
          .toLowerCase()
          .replace(/[^\w\s]/g, "")
          .replace(/ /g, "_") +
        "_" +
        currentUserProfile.id.toString().toLowerCase().substring(0, 5);
    } else {
      newurl = "";
    }
    const values = {
      isStreamer: toggledIsStreamer,
      url: newurl,
    };
    try {
      updateUserProfile(values);
      toast({
        title: "Success",
        description: "Data has been updated",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "ERORR",
        description: error.message,
        status: "error",
        isClosable: true,
      });
    } finally {
      // setSubmitting(false);
    }
  }

  if (!currentUserProfile.isStreamer) {
    return (
      <>
        <Box flex='1' textAlign='center' w='full'>
          {!currentUserProfile.isStreamer && (
            <Box maxWidth='400px' mx='auto' my='10'>
              <Text>
                You are a streamer yourself and want to use STREAMTEAM for your
                viewers? Go ahead and create your own Streamer Homepage.
              </Text>
            </Box>
          )}
          <Button variant='primary' size='lg' onClick={handleToggleIsStreamer}>
            Activate Streamer Homepage
          </Button>
          <Button
            variant='solid'
            m='10px'
            size='lg'
            as={HashLink}
            to='/#tellmemore'
          >
            Tell more more
          </Button>
        </Box>
      </>
    );
  }

  return (
    <Box>
      <Formik
        initialValues={{
          url: currentUserProfile.url || "",
        }}
        enableReinitialize={true}
        validationSchema={Yup.object({
          url: Yup.string()
            .max(30)
            .matches(/^[a-z0-9_-]+$/, "Only small letters, numbers or - _")
            .required(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          getStreamerUidByURL(values.url).then((isDuplicateExists) => {
            if (
              isDuplicateExists !== false &&
              isDuplicateExists !== currentUserProfile.id
            ) {
              toast({
                title: "ERROR",
                description:
                  "Sorry, URL already been taken. Please choose another one!",
                status: "error",
                isClosable: true,
              });
              setSubmitting(false);
            } else {
              try {
                updateUserProfile(values);
                toast({
                  title: "Success",
                  description: "Data has been updated",
                  status: "success",
                  isClosable: true,
                });
              } catch (error) {
                toast({
                  title: "ERORR",
                  description: error.message,
                  status: "error",
                  isClosable: true,
                });
              } finally {
                setSubmitting(false);
              }
            }
          });
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <FormControl>
              <Stack w='full'>
                <HStack display='flex'>
                  {/* <Box flex='220px 0 0' textAlign='right'>
                    Streamer Homepage
                  </Box> */}
                </HStack>

                {currentUserProfile.isStreamer && (
                  <Box w='full'>
                    <HStack display='flex' pb='10'>
                      <Box flex='220px 0 0'>
                        <Text fontSize='lg' mt='17px' textAlign='right  '>
                          {process.env.REACT_APP_SELF_DOMAIN}/
                        </Text>
                      </Box>
                      <Box flex='1'>
                        <MyTextInput name='url' label='Your URL' />
                      </Box>
                    </HStack>

                    <Button
                      isLoading={isSubmitting}
                      isDisabled={isSubmitting || !isValid || !dirty}
                      type='submit'
                      variant='primary'
                      ml='230px'
                      minWidth='200px'
                    >
                      Update
                    </Button>
                    <Box float='right' mt='100px'>
                      <Button
                        size='sm'
                        variant='warning'
                        onClick={handleToggleIsStreamer}
                      >
                        Disable Streamer Homepage
                      </Button>
                    </Box>
                  </Box>
                )}
              </Stack>
            </FormControl>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
