import { Image } from "@chakra-ui/image";
import React from "react";

export default function LogoHorizontal({
  src,
  margin = "10px 0px 0px 20px",
  height = "auto",
  width = "400px",
  padding = "0px",
  ...rest
}) {
  return (
    <Image
      src={src}
      margin={margin}
      padding={padding}
      height={height}
      width={"400px"}
      maxWidth={"50%"}
      borderRadius={"20px"}
      border={"5px solid #ffffff1a"}
      {...rest}
    />
  );
}
