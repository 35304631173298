import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Stack,
  VStack,
  Text,
} from "@chakra-ui/react";
import LogoHorizontal from "../../app/components/Logo/LogoHorizontal";
import { FiChevronsDown } from "react-icons/fi";
import { useSelector } from "react-redux";
import TextWallContainer from "../../app/components/Text/TextWallContainer";
import MyMainHeading from "../../app/components/Text/MyMainHeading";
import MySubHeading from "../../app/components/Text/MySubHeading";
import MyParagraph from "../../app/components/Text/MyParagraph";
import AboutImage from "../../app/components/AboutImage/AboutImage";
import { Link as ChakraLink } from "@chakra-ui/react";

export default function Landing({ props }) {
  const navigate = useNavigate();

  const { prevLocation } = useSelector((state) => state.auth);
  const laststreamerUrl = localStorage.getItem("laststreamerUrl");

  useEffect(() => {
    if (
      laststreamerUrl &&
      prevLocation?.pathname !== laststreamerUrl &&
      prevLocation?.pathname !== "/" &&
      prevLocation?.pathname !== "/streamersettings"
    ) {
      // console.log(prevLocation);
      navigate(laststreamerUrl);
    }
  }, [navigate, laststreamerUrl, prevLocation]);

  return (
    <>
      <Flex
        direction='column'
        align='center'
        maxW={{ xl: "1200px" }}
        minH='100vh'
        m='0 auto'
        {...props}
      >
        <VStack
          minH='70vh'
          align='center'
          direction='column'
          justify={{ base: "center", md: "space-around", xl: "space-between" }}
          m='auto'
          spacing='40px'
          p='20px'
        >
          <VStack spacing='30px' maxWidth='900px' align='left'>
            <Box my='40px'>
              <LogoHorizontal
                mt='8px'
                margin='0px'
                display='inline'
                height='70px'
                maxHeight='160px'
              />
            </Box>
            <Heading
              as='h1'
              fontSize={{ base: "40px", md: "64px", xl: "74px" }}
              // fontWeight='bold'
              // color='primary.800'
              textAlign={{ base: "center", sm: "left" }}
            >
              Streamteam helps you to create{" "}
              <Box display='inline' whiteSpace='nowrap' color={"teal.300"}>
                great memories
              </Box>{" "}
              for your viewers.
            </Heading>
            <Text
              textAlign={{ base: "center", sm: "left" }}
              fontSize='xl'
              // maxWidth='500px'
            >
              When playing with your viewers, streamteam is the right tool to
              select them in the entertaining way. Form a streamteam!{" "}
              <Box display='inline' color='gray.500'>
                #playingwithviewers #streamteam
              </Box>
            </Text>

            <Stack
              direction={["column", "row"]}
              align={{ base: "center", sm: "left" }}
            >
              <Link to='/streamersettings'>
                <Button
                  variant='primary'
                  py='4'
                  px='4'
                  lineHeight='1'
                  size='lg'
                >
                  Create your own Streamteam
                </Button>
              </Link>

              <HashLink smooth to='/#tellmemore'>
                <Button size='lg'>
                  Tell me more <FiChevronsDown />
                </Button>
              </HashLink>
            </Stack>
          </VStack>
        </VStack>
        <Center></Center>
      </Flex>
      <Flex
        direction='column'
        align='center'
        maxW={{ xl: "900px" }}
        minH='70vh'
        m='0 auto'
        {...props}
        id='tellmemore'
        p='0px'
      >
        <TextWallContainer>
          <MyMainHeading>About</MyMainHeading>
          <MySubHeading mt='30px' size='md'>
            streamteam.gg
          </MySubHeading>
          {/* <MyParagraph>
            ( If you’re not a streamer yourself, please jump right to the “About
            section for viewers”. )
          </MyParagraph> */}
          <MyParagraph>
            <AboutImage src={`/assets/about/streamer.png`} float={"right"} />
            If you are a streamer and you’re playing a lot of online multiplayer
            games on stream, you may already have considered playing directly
            with some of your viewers. Perchance you already did so. But as soon
            as you have more than a handful of viewers, how do you decide whom
            to play with? How do you pick four mates out of 100 or 10.000
            interested fans?
          </MyParagraph>
          <MyParagraph>
            <AboutImage src={`/assets/about/lobby.png`} float={"right"} />
            That’s where STREAMTEAM kicks in: After creating a lobby on
            streamteam.gg, your viewers can conveniently login with their Twitch
            account and join your lobby with very little effort. As soon as you
            decide to start the selection process, you have several “modules” at
            your disposal to use to “decimate” and select the needed players to
            form a streamteam and finally to play with them for this session.
            These “modules” are half the fun for streamteam as they not only
            enable you to create live content for your users by creating a kind
            of “mini gameshow”, but they also give an opportunity to select the
            users much smarter than just randomly or first-come-first-serve.
          </MyParagraph>
          <MyParagraph>
            <AboutImage src={`/assets/about/selectors.png`} float={"right"} />
            Maybe you want to play with those who already tried so many times,
            but haven’t succeeded yet. Or those that can answer some trivia
            questions the best. Or still random, but with a little more tension.
          </MyParagraph>
          <MyParagraph>Intrigued?</MyParagraph>
          <Stack direction={"column"} align={"left"}>
            <Link to='/streamersettings'>
              <Button
                variant='primary'
                py='4'
                px='4'
                mt='10px'
                lineHeight='1'
                size='lg'
              >
                Create your own Streamteam
              </Button>
            </Link>

            <ChakraLink pt={"30px"} as={Link} to={"/about/streamerfeatures"}>
              Or read about features in detail.
            </ChakraLink>
          </Stack>
        </TextWallContainer>
      </Flex>
    </>
  );
}
