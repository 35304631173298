import { Button, ButtonGroup } from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../app/common/modals/modalReducer";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";

import { updateLobbyUsersBulkStatusInFirebase } from "../../../app/firestore/firebaseService";
import { setLobbyGameInProgress } from "../common/SelectorHelpers";

export default function Thanos() {
  const dispatch = useDispatch();
  const { selectedLobby } = useSelector((state) => state.lobby);

  /* Randomize array in-place using Durstenfeld shuffle algorithm */
  function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  function getRandomHalf(arr, num) {
    var result = [];

    // result = [...arr].filter((sel) => Math.round(Math.random()));
    result = shuffleArray(arr);
    result.splice(num, arr.length - num);
    // console.log(result);
    return result;
  }

  function handleCommitAction() {
    setLobbyGameInProgress(selectedLobby.lobbyId, "Thanos");
    let availableUsers = [...selectedLobby.users].filter(
      (sel) => sel.status !== "not_selected"
    );
    dispatch(closeModal());
    const newUsers = getRandomHalf(
      availableUsers,
      Math.floor(availableUsers.length / 2)
    );

    updateLobbyUsersBulkStatusInFirebase(
      selectedLobby.lobbyId,
      newUsers,
      "vanishing"
    );
    setLobbyGameInProgress(selectedLobby.lobbyId, "");

    setTimeout(() => {
      updateLobbyUsersBulkStatusInFirebase(
        selectedLobby.lobbyId,
        newUsers,
        "not_selected"
      );
    }, 4000);
  }

  function handleCancel() {
    dispatch(closeModal());
    setLobbyGameInProgress(selectedLobby.lobbyId, "");
  }

  return (
    <ModalWrapper size='xl' header='Thanos'>
      <p>Erase half of the lobby and restore balance?</p>
      <ButtonGroup mt='10'>
        <Button variant='primary' onClick={() => handleCommitAction()}>
          Yes, snip fingers!
        </Button>
        <Button onClick={() => handleCancel()}>No. Cancel.</Button>
      </ButtonGroup>
    </ModalWrapper>
  );
}
