import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../async/asyncReducer";
import { dataFromSnapshot } from "../firestore/firestoreService";

export default function useFirestoreDoc({
  query,
  data,
  deps,
  shouldExecute = true,
  afterFinished = function () {},
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!shouldExecute) {
      return;
    }

    dispatch(asyncActionStart());
    const unsubscribe = query().onSnapshot(
      (snapshot) => {
        if (!snapshot.exists) {
          dispatch(
            asyncActionError({
              code: "not-found",
              message: "Could not find document",
            })
          );
          afterFinished();
          return;
        }

        data(dataFromSnapshot(snapshot));
        dispatch(asyncActionFinish());
        afterFinished();
      },
      (error) => {
        console.log(error);
        dispatch(asyncActionError(error));
      }
    );
    return () => {
      unsubscribe();
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
