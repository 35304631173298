import React from "react";
import { Button } from "@chakra-ui/react";
import { Box, Stack } from "@chakra-ui/layout";
import { Link } from "react-router-dom";

export default function AboutTab({ profile, isCurrentUser }) {
  return (
    <Stack>
      <Box>
        {isCurrentUser && (
          <Box textAlign='right'>
            <Button as={Link} to={"/settings"}>
              Edit Profile
            </Button>
          </Box>
        )}

        <div style={{ margiBottom: 10 }}>
          <div>{profile.description || null}</div>
        </div>
      </Box>
    </Stack>
  );
}
