import { whiten, darken, mode } from "@chakra-ui/theme-tools";

export const ButtonStyles = {
  // Styles for the base style
  baseStyle: {
    _focus: { boxShadow: "none" },
    maxWidth: "300px",
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    primary: (props) => ({
      bg: "primary",
      color: "white",
      _hover: {
        bg: mode(darken("primary", 20), whiten("primary", 20))(props),
        textDecoration: "None",
      },
    }),
    secondary: (props) => ({
      bg: "secondary",
      color: "white",
      _hover: {
        bg: mode(darken("secondary", 20), whiten("secondary", 20))(props),
      },
    }),
    passive: (props) => ({
      bg: "gray.600",
      color: "white",
      _hover: {
        bg: "gray.600",
      },
    }),
    warning: (props) => ({
      bg: "darkred",
      color: "white",
      _hover: {
        bg: mode(darken("darkred", 20), whiten("darkred", 20))(props),
      },
    }),
    primaryOutline: (props) => ({
      bg: "transparent",
      border: "1px solid",
      borderColor: "primary",
      color: "primary",
      _hover: {
        boxShadow: "md",
      },
    }),
    google: (props) => ({
      bg: "google",
      color: "white",
      _hover: {
        bg: mode(darken("google", 20), whiten("google", 20))(props),
      },
    }),
    twitch: (props) => ({
      bg: "twitch",
      color: "white",
      _hover: {
        bg: mode(darken("twitch", 20), whiten("twitch", 20))(props),
      },
    }),
    discord: (props) => ({
      bg: "discord",
      color: "white",
      _hover: {
        bg: mode(darken("discord", 20), whiten("discord", 20))(props),
      },
    }),
  },
  // The default `size` or `variant` values
  defaultProps: {},
};
