import { Box, Heading, Stack } from "@chakra-ui/layout";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useColorModeValue } from "@chakra-ui/color-mode";
import StreamerSettingsMainTab from "./StreamerSettingsMainTab";
import StreamerSettingsAboutTab from "./StreamerSettingsAboutTab";
import StreamerSettingsAdvancedTab from "./StreamerSettingsAdvancedTab";
import Vcontainer from "../../../app/components/Vcontainer/Vcontainer";

import { Link as ChakraLink } from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import StreamerSettingsQuizTab from "./StreamerSettingsQuizTab";

export default function StreamerSettingsPage() {
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { loading, error } = useSelector((state) => state.async);
  const navigate = useNavigate();
  const bg = useColorModeValue("gray.200", "gray.900");
  const [tabIndex, setTabIndex] = React.useState(0);

  let { tabpage } = useParams();
  if (!tabpage) tabpage = "main";

  const tabs = useMemo(() => ["main", "about", "quiz", "advanced"], []);

  useEffect(() => {
    setTabIndex(parseInt(tabs.indexOf(tabpage)));
  }, [tabs, tabpage]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    navigate(`/streamersettings/${tabs[index]}`);
  };

  if ((loading && !currentUserProfile) || (!currentUserProfile && !error))
    return <LoadingComponent content='Loading Profile ...' />;

  return (
    <Vcontainer>
      <Heading>Streamer Settings</Heading>
      <Tabs
        variant='line'
        orientation='horizontal'
        index={tabIndex}
        onChange={handleTabsChange}
      >
        <TabList flex='0 0 200px' m='3'>
          <Tab _focus={{ boxShadow: "none" }}>Main</Tab>
          {currentUserProfile.url && (
            <>
              <Tab _focus={{ boxShadow: "none" }}>About</Tab>
              <Tab _focus={{ boxShadow: "none" }}>Quiz</Tab>
              <Tab _focus={{ boxShadow: "none" }}>Advanced</Tab>
            </>
          )}
        </TabList>

        {currentUserProfile.url && (
          <Box textAlign='right' m='3'>
            Your homepage:{" "}
            <ChakraLink
              as={Link}
              to={`/${currentUserProfile.url}`}
            >{`${process.env.REACT_APP_SELF_DOMAIN}/${currentUserProfile.url}`}</ChakraLink>
          </Box>
        )}

        <Box
          bg={bg}
          p='5'
          borderRadius='20'
          minH='calc( 100vh - 300px )'
          flex='1'
        >
          <TabPanels>
            <TabPanel>
              <StreamerSettingsMainTab
                currentUserProfile={currentUserProfile}
              />
            </TabPanel>
            {currentUserProfile.url && (
              <TabPanel>
                <StreamerSettingsAboutTab
                  currentUserProfile={currentUserProfile}
                />
              </TabPanel>
            )}
            {currentUserProfile.url && (
              <TabPanel>
                <StreamerSettingsQuizTab
                  currentUserProfile={currentUserProfile}
                />
              </TabPanel>
            )}
            {currentUserProfile.url && (
              <TabPanel>
                <StreamerSettingsAdvancedTab
                  currentUserProfile={currentUserProfile}
                />
              </TabPanel>
            )}
          </TabPanels>
        </Box>
      </Tabs>

      <Stack></Stack>
    </Vcontainer>
  );
}
