import React from "react";
import MyMainHeading from "../../app/components/Text/MyMainHeading";
import MyParagraph from "../../app/components/Text/MyParagraph";
import MySubHeading from "../../app/components/Text/MySubHeading";
import TextWallContainer from "../../app/components/Text/TextWallContainer";
import Vcontainer from "../../app/components/Vcontainer/Vcontainer";

export default function AboutViewerFeatures(props) {
  return (
    <Vcontainer maxW='960px' margin='auto'>
      <TextWallContainer>
        <MyMainHeading>About: Features</MyMainHeading>
        <MySubHeading>User Accounts</MySubHeading>
        <MyParagraph>
          Before Your viewers need to register on streamteam.gg before they can
          join a lobby. They can do so by logging in with their twitch.tv
          account or by creating an account with email and password combination.
          They also might use their google account to log in.
        </MyParagraph>
      </TextWallContainer>
    </Vcontainer>
  );
}
