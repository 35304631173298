import { Stack } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorComponent from "../../../app/common/errors/ErrorCompentent";
import Vcontainer from "../../../app/components/Vcontainer/Vcontainer";
import {
  getStreamerUidByURL,
  getUserProfile,
} from "../../../app/firestore/firestoreService";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { listenToSelectedUserProfile } from "../profileActions";
import AboutTab from "./AboutTab";
// import ProfileContent from "./ProfileContent";
import ProfileHeader from "./ProfileHeader";
import ProfileStats from "./ProfileStats";

export default function ProfilePage() {
  const { url, id } = useParams();
  const dispatch = useDispatch();
  const { selectedUserProfile, currentUserProfile } = useSelector(
    (state) => state.profile
  );
  const [streamerId, setstreamerId] = useState(undefined);
  const { currentUser } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.async);
  let profile;

  useEffect(() => {
    getStreamerUidByURL(url).then((uid) => {
      if (streamerId !== uid) {
        setstreamerId(uid);
      }
    });
  }, [streamerId, url]);

  useFirestoreDoc({
    query: () => getUserProfile(id),
    data: (profile) => dispatch(listenToSelectedUserProfile(profile)),
    deps: [dispatch, id],
    shouldExecute: id !== currentUser?.uid,
  });

  if (id === currentUser?.uid) {
    profile = currentUserProfile;
  } else {
    profile = selectedUserProfile;
  }

  if (!loading && profile === null) return <ErrorComponent />;

  if ((loading && (!profile || profile === null)) || (!profile && !error))
    return <LoadingComponent content='Loading Profile ...' />;

  return (
    <Vcontainer>
      <Stack>
        <ProfileHeader
          profile={profile}
          isCurrentUser={currentUser.uid === profile.id}
        />
        <AboutTab
          profile={profile}
          isCurrentUser={currentUser.uid === profile.id}
        />
        {streamerId && (
          <ProfileStats profile={profile} streamerId={streamerId} />
        )}

        {!streamerId &&
          profile.joinedStreamers?.map((streamer) => (
            <ProfileStats
              key={streamer}
              profile={profile}
              streamerId={streamer}
            />
          ))}
      </Stack>
    </Vcontainer>
  );
}
