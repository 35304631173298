import { Form, Formik, useField } from "formik";
import React, { useState } from "react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import * as Yup from "yup";
import { addUserAccountToFirestore } from "../../../app/firestore/firestoreService";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button, Select } from "@chakra-ui/react";
import { Badge, Box, Stack, Text } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { getUserAuthToken } from "../../../app/firestore/firebaseService";

export function AccountsSelectInput({
  label,
  options,
  setSelectedAccountType,
  ...props
}) {
  const [field, meta, helpers] = useField(props);

  return (
    <Box position='relative'>
      <Select
        mt='7'
        mb='3'
        isInvalid={meta.touched && !!meta.error}
        variant='flushed'
        size='md'
        width='250px'
        value={field.value || ""}
        onChange={(e) => {
          helpers.setValue(e.target.value);
          setSelectedAccountType(e.target.value);
        }}
        onBlur={() => helpers.setTouched(true)}
        {...props}
      >
        {options.map((option) => (
          <option value={option.key} key={option.key}>
            {option.name}
          </option>
        ))}
      </Select>
      <FormLabel top='7px' htmlFor='name'>
        {label}
      </FormLabel>

      <Badge
        marginTop='0'
        position='absolute'
        bottom='-10px'
        left='0'
        colorScheme='red'
      >
        {meta.touched && meta.error ? meta.error : null}
      </Badge>
    </Box>
  );
}

export default function AccountFormAdd({
  currentUserProfile,
  accountTypes,
  accountType,
  handleClose,
}) {
  const toast = useToast();
  const [selectedAccountType, setSelectedAccountType] = useState(undefined);

  const accountTypeName = accountTypes.filter((a) => a.key === accountType)
    .length
    ? accountTypes.filter((a) => a.key === accountType)[0].name
    : undefined;
  // .map((a) => a.name)

  async function handleDiscordAdd() {
    const token = await getUserAuthToken();

    const url = `${process.env.REACT_APP_FUNCTION_URL}/api/discord/account/auth?token=${token}&returnto=${window.location.href}`;
    console.log(url);
    window.location.replace(url);
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        type: accountType,
        value: "",
      }}
      validationSchema={Yup.object({
        type: Yup.string().max(20).trim().required(),
        value: Yup.string()
          .max(30)
          .trim()
          .matches(/[abcdefghijklmnopqrstuvwxyz]+/, "Is not in correct format")
          .required(),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          if (values.type === "discord") {
            return;
          }
          await addUserAccountToFirestore(currentUserProfile, values);
          resetForm();
          if (handleClose) handleClose();
          toast({
            title: "Success",
            description: "Account has been added",
            status: "success",
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            isClosable: true,
          });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          <FormControl>
            <Stack w='full' spacing='10'>
              {accountType && (
                <>
                  {/* <Heading size='md'>
                    {accountTypes
                      .filter((a) => a.key === accountType)
                      .map((a) => a.name)}
                  </Heading> */}

                  <Text opacity='0.5'>
                    {accountTypes
                      .filter((a) => a.key === accountType)
                      .map((a) => a.description)}
                  </Text>
                  <Text>
                    Please enter your <b>{accountTypeName}</b> Account.
                    <br /> It's needed so the streamer can invite you to the
                    game.
                  </Text>
                  <input type='hidden' name='type' />
                </>
              )}

              <Stack
                display='flex'
                w='full'
                direction={["column", "column", "row"]}
              >
                {!accountType && (
                  <AccountsSelectInput
                    name='type'
                    label='Type'
                    setSelectedAccountType={setSelectedAccountType}
                    options={[
                      { name: "Please select...", key: "" },
                      ...accountTypes,
                    ]}
                  />
                )}

                {selectedAccountType !== "discord" &&
                  accountType !== "discord" && (
                    <MyTextInput
                      mt='35px'
                      flex='1'
                      name='value'
                      label={accountTypeName ? accountTypeName : "Value"}
                    />
                  )}
                {(selectedAccountType === "discord" ||
                  accountType === "discord") && (
                  <Box pt='28px' pl='20px'>
                    <Button
                      size='md'
                      variant='primary'
                      onClick={() => handleDiscordAdd()}
                    >
                      Add & Verify Discord Account
                    </Button>
                  </Box>
                )}
              </Stack>

              {accountType !== "discord" && selectedAccountType !== "discord" && (
                <Button
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting || !isValid}
                  type='submit'
                  variant='primary'
                >
                  Add Account
                </Button>
              )}
            </Stack>
          </FormControl>
        </Form>
      )}
    </Formik>
  );
}
