import { Button, IconButton } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, Spacer, Heading } from "@chakra-ui/layout";
import { BsTrash } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import { FaDoorOpen, FaDoorClosed } from "react-icons/fa";
import { IoMdDoneAll } from "react-icons/io";
import { BiReset } from "react-icons/bi";
import { format } from "date-fns";

import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteLobbyFromFirebase,
  updateLobbyAllUserStatusInFirebase,
  updateLobbyInFirebase,
} from "../../../app/firestore/firebaseService";
import {
  deleteDiscordChannelViaFirestore,
  updateLobbylogInFirestore,
  updateStreamersUsersStatsFirestore,
  updateUserProfile,
} from "../../../app/firestore/firestoreService";
import { clearSelectedLobby } from "../lobbyActions";

import { openModal } from "../../../app/common/modals/modalReducer";
import { countOpenSlots } from "../../../app/common/util/util";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { removeFromSelectedUsers } from "../../selectors/common/SelectorHelpers";
import { useToast } from "@chakra-ui/toast";

export function removeUser({ lobbyId, selectedLobby, tindex, pindex, id }) {
  removeFromSelectedUsers(lobbyId, selectedLobby, tindex, pindex, id);
}

export default function LobbyAdminMenu({
  lobbyId,
  streamerProfile,
  currentUserProfile,
  selectedLobby,
}) {
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(undefined);
  const cancelRef = React.useRef();
  const toast = useToast();
  const { innerWidth } = window;

  const numJoinedUsers = selectedLobby.users?.filter(
    (elem) => elem.status === "joined"
  ).length;

  useEffect(() => {
    if (selectedLobby.quiz?.status === "in_progress") {
      if (streamerProfile.id === currentUserProfile?.id) {
        dispatch(openModal({ modalType: "Quiz" }));
      }
    }
  }, [selectedLobby, dispatch, currentUserProfile, streamerProfile]);
  React.useEffect(() => {}, [innerWidth]); // IMPORTANT, This will cause react to update depending on change of this value

  useEffect(() => {
    if (selectedLobby.gameinprogress) {
      if (streamerProfile.id === currentUserProfile?.id) {
        dispatch(openModal({ modalType: selectedLobby.gameinprogress }));
      }
    }
  }, [
    selectedLobby.gameinprogress,
    dispatch,
    streamerProfile.id,
    currentUserProfile?.id,
  ]);

  async function handleStatusChangeLobby(newStatus) {
    const values = {
      status: newStatus,
      quiz: "",
    };
    try {
      updateLobbyInFirebase(lobbyId, values);
      updateLobbylogInFirestore(lobbyId, streamerProfile.id, values);
    } catch (error) {
      console.log(error.message);
    }
  }

  function handleReset() {
    setConfirmOpen(undefined);
    handleStatusChangeLobby("open");
    updateLobbyAllUserStatusInFirebase(lobbyId, "joined");
    deleteDiscordChannelViaFirestore(lobbyId);
  }

  async function handleDeleteLobby() {
    try {
      if (streamerProfile.createdLobby) {
        await deleteLobbyFromFirebase(streamerProfile.createdLobby).then(
          (response) => {
            updateUserProfile({
              createdLobby: null,
            });
            dispatch(clearSelectedLobby());
            deleteDiscordChannelViaFirestore(lobbyId);
          }
        );
      }
    } catch (error) {
      // toast.error(error.message);
    }
  }

  async function handleFinishLobby() {
    try {
      if (streamerProfile.createdLobby) {
        updateLobbyInFirebase(lobbyId, {
          status: "finished",
        });
        updateUserProfile({
          createdLobby: null,
        });
        deleteDiscordChannelViaFirestore(lobbyId);

        selectedLobby.users.map((elem) => {
          updateStreamersUsersStatsFirestore(
            streamerProfile.id,
            elem.userId,
            elem.status
          );
          return true;
        });
      }
    } catch (error) {
      // toast.error(error.message);
    }
  }

  function CrumbItem({ children, isLast, status, notclickable, ...rest }) {
    let active = "";
    let clickable = "";

    let hoverStyle = {};
    if (selectedLobby.status === status) active = "active";
    if (streamerProfile.id === currentUserProfile?.id && !notclickable) {
      clickable = "clickable";
      hoverStyle = {
        backgroundColor: "gray.300",
        borderLeftColor: "gray.300",
        _after: {
          borderLeftColor: "gray.300",
        },
      };
    } else {
      rest = null;
    }
    const bordercolor = useColorModeValue("#fff", "gray.800");

    if (innerWidth < 840) {
      return (
        <Box {...rest} display='inline-block' px='5px' py='5px'>
          <Text
            color={active === "active" ? "teal.300" : ""}
            fontWeight={"normal"}
            display='inline'
            fontSize='sm'
          >
            {children}
          </Text>
          {!isLast && (
            <Box display='inline' opacity={0.5}>
              {" "}
              &gt;
            </Box>
          )}
        </Box>
      );
    } else {
      return (
        <Heading
          flex='1'
          {...rest}
          color={"gray.900"}
          fontWeight='bold'
          textTransform='uppercase'
          fontSize={{ base: "sm", md: "xl" }}
          // fontFamily='rajdhani'
          bg={active === "active" ? "gray.300" : "passive"}
          _after={{
            borderLeftColor: active === "active" ? "gray.300" : "passive",
          }}
          _hover={hoverStyle}
          className={`crumbitembase ${
            innerWidth > 766 ? "crumbitem" : ""
          } ${active} ${clickable}`}
          _before={{ borderLeftColor: bordercolor }}
          minWidth='160px'
          // maxWidth={`${innerWidth / 3 - 7}px`}
        >
          {children}
        </Heading>
      );
    }
  }

  function AdminMenu() {
    return (
      <Menu placement='bottom-end'>
        <MenuButton
          as={IconButton}
          name='lobby_user_menu_button'
          // rightIcon={<ChevronDownIcon />}
          variant='outline'
          icon={<HamburgerIcon />}
        />
        {/* Actions
    </MenuButton> */}
        <MenuList>
          <MenuItem
            as={Link}
            name='edit_lobby'
            to={`/editLobby/${streamerProfile.createdLobby}`}
            icon={<AiOutlineEdit />}
          >
            Edit Lobby
          </MenuItem>
          {selectedLobby.status === "open" && (
            <MenuItem
              onClick={() => handleStatusChangeLobby("in_progress")}
              name='close_lobby'
              icon={<FaDoorClosed />}
            >
              Close Lobby for joins
            </MenuItem>
          )}
          {selectedLobby.status === "in_progress" && (
            <MenuItem
              onClick={() => handleStatusChangeLobby("open")}
              name='open_lobby'
              icon={<FaDoorOpen />}
            >
              Re-Open Lobby
            </MenuItem>
          )}
          {selectedLobby.status === "all_selected" && (
            <MenuItem
              onClick={() =>
                setConfirmOpen({
                  title: "Finish Lobby",
                  body: "Close this lobby?",
                  handle: handleFinishLobby,
                })
              }
              icon={<IoMdDoneAll />}
              name='finish_lobby'
            >
              Finish Lobby
            </MenuItem>
          )}

          <MenuItem
            onClick={() =>
              setConfirmOpen({
                title: "Reset & Re-Open",
                body: "Reset everything and open Lobby?",
                handle: handleReset,
              })
            }
            icon={<BiReset />}
            name='reopen_lobby'
          >
            Reset Selections
          </MenuItem>

          <MenuDivider />
          <MenuItem
            name='delete_lobby'
            onClick={handleDeleteLobby}
            icon={<BsTrash />}
          >
            Delete Lobby
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }

  return (
    <Box position='relative' zIndex='100'>
      <Flex
        bg={innerWidth > 840 ? "transparent" : "passive"}
        borderTopRadius={"20px"}
        m='auto'
        id='crumbs'
        position='relative'
        w='100%'
        wrap='wrap'
      >
        {innerWidth < 840 && <Spacer />}
        <CrumbItem
          status='open'
          borderTopLeftRadius={innerWidth > 766 ? "20px" : "0px"}
          onClick={() =>
            setConfirmOpen({
              title: "Reset & Re-Open",
              body: "Reset everything and open Lobby?",
              handle: handleReset,
            })
          }
        >
          Join
        </CrumbItem>
        <CrumbItem
          status='in_progress'
          active={selectedLobby.status === "in_progress" ? "active" : ""}
          onClick={() => {
            if (
              selectedLobby.users?.filter((elem) => elem.status === "joined")
                .length > 0
            ) {
              handleStatusChangeLobby("in_progress");
              dispatch(openModal({ modalType: "SelectorSelection" }));
            } else {
              if (selectedLobby.status === "all_selected") {
                toast({
                  title: "All selected",
                  description:
                    "Users already have been selected. To reset, go back to Join Phase.",
                  status: "error",
                  isClosable: true,
                });
              } else {
                toast({
                  title: "No Users yet!",
                  description: "Sorry, no users to select from!",
                  status: "error",
                  isClosable: true,
                });
              }
            }
          }}
        >
          Reduce & Select
        </CrumbItem>
        <CrumbItem notclickable={true} status='all_selected'>
          Prepare & Play
        </CrumbItem>
        <CrumbItem
          borderTopRightRadius={innerWidth > 766 ? "20px" : "0px"}
          isLast={true}
          onClick={() =>
            setConfirmOpen({
              title: "Finish up",
              body: "Close this streamteam and lobby?",
              handle: handleFinishLobby,
            })
          }
          status='finished'
        >
          Close
        </CrumbItem>
        {innerWidth < 840 && <Spacer />}
      </Flex>
      <Flex mr='5px' mb='10px' mt='5px'>
        <Box flex='0 0 60px' />
        <Box
          flex='1'
          textAlign={"center"}
          pt='7px'
          opacity='0.5'
          fontSize={["xs", "md"]}
        >
          {selectedLobby.game && (
            <>
              <Text display='inline' textAlign='center'>
                to play <b>{selectedLobby.game}</b>,
              </Text>
              {format(selectedLobby.created, "dd MMM yyyy, HH:ii")}
            </>
          )}
        </Box>
        <Box flex='0 0 60px' textAlign={"right"}>
          {streamerProfile.id === currentUserProfile?.id && <AdminMenu />}
        </Box>
      </Flex>

      {streamerProfile.id === currentUserProfile?.id && (
        <>
          {selectedLobby.status !== "all_selected" && (
            <Flex>
              <Box flex='0 0 60px'></Box>
              <Spacer />
              <Box w='100%' overflow={"hidden"} textAlign='center' pt='0px'>
                <Flex
                  verticalAlign='top'
                  height='50px'
                  pt='0px'
                  position='relative'
                >
                  <Box
                    margin='auto'
                    className={
                      numJoinedUsers >= countOpenSlots(selectedLobby) &&
                      !selectedLobby.gameinprogress &&
                      selectedLobby.status === "in_progress"
                        ? "trapdoor trapdoor_hover"
                        : "trapdoor"
                    }
                  >
                    <Box
                      className={
                        selectedLobby.status === "in_progress"
                          ? "top door"
                          : "top door passive"
                      }
                    ></Box>
                    <Box
                      className={
                        selectedLobby.status === "in_progress"
                          ? "bottom door"
                          : "bottom door passive"
                      }
                    ></Box>
                    <Button
                      size='lg'
                      width='100%'
                      disabled={selectedLobby.gameinprogress}
                      // variant='primary'
                      // color='orange'
                      onClick={() => {
                        dispatch(openModal({ modalType: "SelectorSelection" }));
                      }}
                    >
                      SELECT USERS
                    </Button>

                    {selectedLobby.status === "open" &&
                      numJoinedUsers < countOpenSlots(selectedLobby) && (
                        <Button
                          size='md'
                          position='absolute'
                          left='4px'
                          // disabled
                          pointerEvents='none'
                          width='247px'
                          top='4px'
                          zIndex='100'
                          variant='passive'
                          border='2px solid #000'
                        >
                          Waiting for users (Min.{" "}
                          {countOpenSlots(selectedLobby) - numJoinedUsers})
                        </Button>
                      )}

                    {selectedLobby.status === "open" &&
                      numJoinedUsers === countOpenSlots(selectedLobby) && (
                        <Button
                          size='md'
                          position='absolute'
                          left='4px'
                          width='247px'
                          top='4px'
                          zIndex='100'
                          variant='primary'
                          border='2px solid #000'
                          onClick={() => {
                            if (
                              selectedLobby.users?.filter(
                                (elem) => elem.status === "joined"
                              ).length > 0
                            ) {
                              handleStatusChangeLobby("in_progress");
                            } else {
                              if (selectedLobby.status === "all_selected") {
                                toast({
                                  title: "All selected",
                                  description:
                                    "Users already have been selected. To reset, go back to Join Phase.",
                                  status: "error",
                                  isClosable: true,
                                });
                              } else {
                                toast({
                                  title: "No Users yet!",
                                  description:
                                    "Sorry, no users in lobby yet. But you can close the lobby for joins in the menu.",
                                  status: "error",
                                  isClosable: true,
                                });
                              }
                            }
                          }}
                        >
                          END JOIN PHASE
                        </Button>
                      )}
                  </Box>
                </Flex>

                {/* {(selectedLobby.status === "in_progress" ||
              selectedLobby.status === "open") &&
              selectedLobby.users?.length > 0 &&
              selectedLobby.users?.length >= countOpenSlots(selectedLobby) && (
                
              )} */}
              </Box>
              <Spacer />
              <Box flex='0 0 50px' pt='20px' zIndex='500'></Box>
            </Flex>
          )}
        </>
      )}
      <AlertDialog
        isCentered
        isOpen={confirmOpen !== undefined}
        leastDestructiveRef={cancelRef}
        onClose={() => setConfirmOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent backgroundColor={"modalBg"}>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {confirmOpen && confirmOpen.title}
            </AlertDialogHeader>

            <AlertDialogBody>{confirmOpen && confirmOpen.body}</AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme='green'
                onClick={() => confirmOpen.handle()}
                mr={3}
              >
                Confirm
              </Button>
              <Button ref={cancelRef} onClick={() => setConfirmOpen(undefined)}>
                Cancel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
