// https://htmltidy.net/
// 1) Copy Text from Google Doc
// 2) Past in htmltidy
// 3) Click "Tidy"
// 4) Past Result here

export default function TextPrivacyPolicy() {
  return (
    <div class='legal'>
      <h1>Cookies etc</h1>
      <p>...</p>
    </div>
  );
}
