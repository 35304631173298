import { Form, Formik } from "formik";
import React from "react";

import MyTextArea from "../../../app/common/form/MyTextArea";
import MyTextInput from "../../../app/common/form/MyTextInput";
import * as Yup from "yup";
import { updateUserProfile } from "../../../app/firestore/firestoreService";
import { FormControl } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/react";
import { Box, Stack, Text, HStack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";

export default function StreamerSettingsAboutTab({ currentUserProfile }) {
  const toast = useToast();

  return (
    <Box>
      <Formik
        initialValues={{
          streamer_description: currentUserProfile.streamer_description || "",
          twitchChannel: currentUserProfile.twitchChannel || "",
        }}
        validationSchema={Yup.object({
          twitchChannel: Yup.string()
            .max(50)
            .matches(/^[a-z0-9_-]+$/, "Only small letters, numbers or - _"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await updateUserProfile(values);
            toast({
              title: "Success",
              streamer_description: "Data has been updated",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            // toast.error(error.message);
            console.log(error.message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <FormControl>
              <Stack w='full'>
                <Stack
                  display='flex'
                  w='full'
                  direction={["column", "column", "row"]}
                >
                  <HStack display='flex'>
                    <Box flex='120px 0 0'>
                      <Text fontSize='lg' mt='17px' textAlign='right  '>
                        www.twitch.tv/
                      </Text>
                    </Box>
                    <Box flex='1'>
                      <MyTextInput
                        flex='1'
                        name='twitchChannel'
                        label='Your Twitch Channel'
                        labelOffset='-145px'
                      />
                    </Box>
                  </HStack>
                </Stack>
                <MyTextArea
                  rows='10'
                  name='streamer_description'
                  label='Description'
                  placeholder='Something about your channel and how do you want to play with your users (games, schedules, focus, etc)...'
                />

                <Button
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting || !isValid}
                  type='submit'
                  variant='primary'
                >
                  Update profile
                </Button>
              </Stack>
            </FormControl>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
