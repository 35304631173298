import { Image } from "@chakra-ui/image";
import { Box, Container, Flex, Heading, Text, VStack } from "@chakra-ui/layout";
import { chakra } from "@chakra-ui/system";
import React, { useEffect, useState } from "react";
import { FiUpload } from "react-icons/fi";
import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { openModal } from "../../../app/common/modals/modalReducer";
import {
  // followUser,
  getFollowingDoc,
  // unfollowUser,
} from "../../../app/firestore/firestoreService";
import { setFollowUser } from "../profileActions";
import { CLEAR_FOLLOWINGS } from "../profileConstants";

export default function ProfileHeader({ profile, isCurrentUser }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { innerWidth } = window;
  // const { followingUser } = useSelector((state) => state.profile);

  useEffect(() => {
    if (isCurrentUser) return;
    setLoading(true);
    async function fetchFollowingDoc(params) {
      try {
        const followDoc = await getFollowingDoc(profile.id);
        if (followDoc && followDoc.exists) {
          dispatch(setFollowUser());
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
    fetchFollowingDoc().then(() => setLoading(false));
    return () => {
      dispatch({ type: CLEAR_FOLLOWINGS });
    };
  }, [dispatch, profile.id, isCurrentUser]);

  // async function handleFollowUser() {
  //   setLoading(true);
  //   try {
  //     await followUser(profile);
  //     dispatch(setFollowUser());
  //   } catch (error) {
  //     toast.error(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  // async function handleUnfollowUser() {
  //   setLoading(true);
  //   try {
  //     await unfollowUser(profile);
  //     dispatch(setUnfollowUser());
  //   } catch (error) {
  //     toast.error(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // }
  const photoWidth = innerWidth < 800 ? "100px" : "200px";

  const UploadArea = chakra("div", {
    baseStyle: {
      position: "absolute",
      width: photoWidth,
      height: photoWidth,
      margin: "0px",
      cursor: "pointer",
      borderRadius: "100%",
      opacity: "1",
      _hover: {
        bg: "rgba(0, 0, 0, 0.5)",
        opacity: "1",
        color: "white",
      },
      color: "#666",
    },
  });

  return (
    <Container maxW='container.xl'>
      <Flex align='center' justify='center'>
        <Box flex={`0 0 ${photoWidth}`} position='relative'>
          {!loading && (
            <>
              {isCurrentUser && (
                <UploadArea
                  onClick={() => {
                    dispatch(openModal({ modalType: "PhotoUploadWidget" }));
                  }}
                >
                  {!profile.photoURL && (
                    <Box position='absolute' bottom='10px' width='100%'>
                      <VStack>
                        <Text>Upload Photo</Text>
                        <FiUpload size={30} />
                      </VStack>
                    </Box>
                  )}
                </UploadArea>
              )}
              <Image
                src={profile.photoURL || "/assets/user.png"}
                w={photoWidth}
                p={0}
                borderRadius={200}
              />
            </>
          )}
        </Box>
        <Box flex='1' verticalAlign='middle'>
          <Heading ml='20px'>{profile.displayName}</Heading>
        </Box>
        <Box flex='0 200px'>{/* <Text>Stats</Text> */}</Box>
      </Flex>
    </Container>

    // <Segment>
    //   <Grid>
    //     <Grid.Column width={12}>
    //       <Item.Group>
    //         <Item>
    //           <Item.Image
    //             avatar
    //             size='small'
    //             src={profile.photoURL || "/assets/user.png"}
    //           ></Item.Image>
    //           <Item.Content verticalAlign='middle'>
    //             <Header
    //               as='h1'
    //               style={{ display: "block", marginBottom: 10 }}
    //               content={profile.displayName}
    //             />
    //           </Item.Content>
    //         </Item>
    //       </Item.Group>
    //     </Grid.Column>
    //     <Grid.Column width={4}>
    //       <Statistic.Group>
    //         <Statistic label='Followers' value={profile.followerCount || 0} />
    //         <Statistic label='Following' value={profile.followingCount || 0} />
    //       </Statistic.Group>
    //       {!isCurrentUser && (
    //         <>
    //           <Divider />
    //           <Reveal animated='move'>
    //             <Reveal.Content visible style={{ width: "100%" }}>
    //               <Button
    //                 fluid
    //                 color='teal'
    //                 content={followingUser ? "Following" : "Not Following"}
    //               />
    //             </Reveal.Content>
    //             <Reveal.Content hidden style={{ width: "100%" }}>
    //               <Button
    //                 onClick={
    //                   followingUser
    //                     ? () => handleUnfollowUser()
    //                     : () => handleFollowUser()
    //                 }
    //                 loading={loading}
    //                 fluid
    //                 color={followingUser ? "red" : "green"}
    //                 content={followingUser ? "Unfollow" : "Follow"}
    //               />
    //             </Reveal.Content>
    //           </Reveal>
    //         </>
    //       )}
    //     </Grid.Column>
    //   </Grid>
    // </Segment>
  );
}
