import React from "react";
import { useParams } from "react-router-dom";
import Vcontainer from "../../app/components/Vcontainer/Vcontainer";
import AboutDonation from "./AboutDonation";
import AboutEmail from "./AboutEmail";
import AboutStreamerFeatures from "./AboutStreamerFeatures";
import AboutStreamerHelp from "./AboutStreamerHelp";
import AboutViewerFeatures from "./AboutViewerFeatures";
import AboutViewerHelp from "./AboutViewerHelp";

export default function About() {
  const { section } = useParams();

  switch (section) {
    case "donation":
      return <AboutDonation />;
    case "viewerfeatures":
      return <AboutViewerFeatures />;
    case "viewerhelp":
      return <AboutViewerHelp />;
    case "streamerfeatures":
      return <AboutStreamerFeatures />;
    case "streamerhelp":
      return <AboutStreamerHelp />;
    case "email":
      return <AboutEmail />;

    default:
      return <Vcontainer textAlign='center'>"{section}" not found</Vcontainer>;
  }
}
