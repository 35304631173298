import { Box } from "@chakra-ui/layout";
import React from "react";

export default function MyParagraph({ children }) {
  return (
    <Box
      mt={"25px"}
      clear={"both"}
      overflow={"auto"}
      display={"block"}
      lineHeight={"200%"}
    >
      {children}
    </Box>
  );
}
