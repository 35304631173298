import React from "react";
import MyMainHeading from "../../app/components/Text/MyMainHeading";
import MyParagraph from "../../app/components/Text/MyParagraph";
import MySubHeading from "../../app/components/Text/MySubHeading";
import TextWallContainer from "../../app/components/Text/TextWallContainer";
import Vcontainer from "../../app/components/Vcontainer/Vcontainer";

export default function AboutStreamerFeatures(props) {
  return (
    <Vcontainer maxW='720px' margin='auto'>
      <TextWallContainer>
        <MyMainHeading>About: Features</MyMainHeading>
        <MySubHeading>User Accounts</MySubHeading>
        <MyParagraph>
          Your viewers need to register on streamteam.gg before they can join a
          lobby. They can do so by logging in with their twitch.tv account or by
          creating an account with email and password combination. They also
          might use their google account to log in.
        </MyParagraph>
        <MySubHeading>User Communication</MySubHeading>
        <MyParagraph>
          At the end you want to play with your viewers. To do so, you not only
          need to select them, you also need to communicate with them afterwards
          and add them to your game or similar. To ease this process, you can
          specify what kind of accounts your viewers need to add before they can
          join. This might be a xbox account, a SteamID or some other ingame
          account. But even more important, you want to have them to add their
          discord account.
        </MyParagraph>
        <MySubHeading>Discord</MySubHeading>
        <MyParagraph>
          To make the communication with your streamteam as effortless as
          possible, streamteam can automatically create discord channels and put
          your selected users right into it. Text and voice channels will be
          generated, for both teams if needed. If you don't want to use the
          streamteam discord server, you can still invite your selected players
          to your own server.
        </MyParagraph>
        <MySubHeading>Lobbies</MySubHeading>
        <MyParagraph>
          The heart of your streamteam is a lobby. You can create one with some
          properties, to fit best to your needs, most important the team size.
          If you want to play a private match, you can create a lobby for two
          teams. Or one team if you want to play against someone else or to join
          a public gameserver. You can create one team with up to 8 players or
          two teams up to 6v6.
        </MyParagraph>
        <MySubHeading>Game Modules</MySubHeading>
        <MyParagraph>...</MyParagraph>
        <MySubHeading>Statistics</MySubHeading>
        <MyParagraph>...</MyParagraph>
        <MySubHeading>Community Building</MySubHeading>
        <MyParagraph>...</MyParagraph>
      </TextWallContainer>
    </Vcontainer>
  );
}
