import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { clearSelectedLobby, listenToSelectedLobby } from "../lobbyActions";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import { teamSizes } from "../../../app/api/categoryData";
import { MdAddBox } from "react-icons/md";
// import MySelectInput from "../../../app/common/form/MySelectInput";
import { closeModal, openModal } from "../../../app/common/modals/modalReducer";
import {
  createLobbylogInFirestore,
  deleteDiscordChannelViaFirestore,
  updateLobbylogInFirestore,
  updateUserProfile,
} from "../../../app/firestore/firestoreService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Section from "../../../app/components/section/Section";
import { Box, Heading, HStack, Text } from "@chakra-ui/layout";
import { Checkbox } from "@chakra-ui/react";
import {
  Button,
  ButtonGroup,
  Select,
  Tag,
  TagCloseButton,
  TagLabel,
} from "@chakra-ui/react";
import {
  addLobbyToFirebase,
  listenToLobbyFromFirebase,
  updateLobbyInFirebase,
} from "../../../app/firestore/firebaseService";

import { FormLabel } from "@chakra-ui/form-control";
import { Switch } from "@chakra-ui/switch";
import Vcontainer from "../../../app/components/Vcontainer/Vcontainer";

import { accountTypes } from "../../../app/api/accounttypes";
import { AiOutlineWarning } from "react-icons/ai";

export default function LobbyForm() {
  const DefaultNumPlayersOneTeam = 4;
  const DefaultNumPlayersTwoTeams = 9;
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.async);
  const { selectedLobby } = useSelector((state) => state.lobby);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { id: lobbyId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [accountToBeAdded, setAccountToBeAdded] = useState("");
  // const [numPlayers, setNumPlayers] = useState(undefined);

  let initialValues;
  if (selectedLobby) {
    initialValues = {
      game: selectedLobby.game,
      description: selectedLobby.description ? selectedLobby.description : "",
      numPlayers: selectedLobby.numPlayers,
      numTeams: selectedLobby.numTeams ? selectedLobby.numTeams : 1,
      accountsNeeded: selectedLobby.accountsNeeded,
      dontCreateDiscordChannels: selectedLobby.dontCreateDiscordChannels,
      twitchSubscribersOnly: selectedLobby.twitchSubscribersOnly,
      twitchChannel: selectedLobby.twitchChannel
        ? selectedLobby.twitchChannel
        : currentUserProfile.twitchChannel,
    };
  } else {
    if (currentUserProfile?.lastCreatedLobbySettings) {
      initialValues = currentUserProfile.lastCreatedLobbySettings;
      if (initialValues.accountsNeeded === undefined) {
        initialValues.accountsNeeded = [];
      }
      if (initialValues.twitchChannel === undefined) {
        initialValues.twitchChannel = currentUserProfile.twitchChannel;
      }
    } else {
      initialValues = {
        game: "",
        description: "",
        numPlayers: DefaultNumPlayersOneTeam,
        numTeams: 1,
        accountsNeeded: ["discord"],
        dontCreateDiscordChannels: false,
        twitchSubscribersOnly: false,
        twitchChannel: currentUserProfile.twitchChannel
          ? currentUserProfile.twitchChannel
          : "",
      };
    }
  }

  const validationSchema = Yup.object({
    game: Yup.string().required(),
    twitchChannel: Yup.string()
      .max(50)
      .matches(/^[a-z0-9_-]+$/, "Only small letters, numbers or - _")
      .when("twitchSubscribersOnly", {
        is: (twitchSubscribersOnly) => twitchSubscribersOnly,
        then: Yup.string().required("Field is required"),
      }),
    description: Yup.string(),
    numPlayers: Yup.number(),
    numTeams: Yup.number(),
    dontCreateDiscordChannels: Yup.bool(),
    twitchSubscribersOnly: Yup.bool(),
  });

  useEffect(() => {
    if (location.pathname !== "/createLobby") return;
    dispatch(clearSelectedLobby());
  }, [dispatch, location.pathname]);

  // useFirestoreDoc({
  //   query: () => getStreamerProfile(streamerUid),
  //   data: (profile) => dispatch(listenToStreamerProfile(profile)),
  //   deps: [dispatch, streamerUid],
  //   shouldExecute: streamerUid !== undefined && streamerUid !== false, // undefined === loaded yet, false === not a valid streamerURL / not found
  // });
  // console.log(streamerProfile);
  // console.log(currentUserProfile);

  useEffect(() => {
    const LobbyRef = listenToLobbyFromFirebase(lobbyId);
    LobbyRef.on("value", (snapshot) => {
      if (!snapshot.exists()) return;
      dispatch(listenToSelectedLobby(snapshot.val()));
    });
    return () => {
      dispatch(clearSelectedLobby());
      LobbyRef.off();
    };
  }, [lobbyId, dispatch]);

  async function setCreatedLobbyProfile(id) {
    try {
      updateUserProfile({
        createdLobby: id,
      });
    } catch (error) {
      throw error;
    }
  }

  function handleCloseAccountForm() {
    dispatch(closeModal());
  }

  async function setLastCreatedLobbySettingsToProfile(values) {
    const valuesToSave = {
      game: values.game,
      description: values.description,
      numPlayers: values.numPlayers,
      numTeams: values.numTeams,
      accountsNeeded: values.accountsNeeded,
      twitchChannel: values.twitchChannel ? values.twitchChannel : "",
      twitchSubscribersOnly: values.twitchSubscribersOnly,
    };
    try {
      updateUserProfile({
        lastCreatedLobbySettings: valuesToSave,
      });
    } catch (error) {
      throw error;
    }
  }

  function handleToggleNumTeams(numTeams, setFieldValue) {
    let newTeamsValue;
    if (numTeams === 1) {
      newTeamsValue = 2;
      handleSetNumPlayers(DefaultNumPlayersTwoTeams, setFieldValue);
    } else {
      newTeamsValue = 1;
      handleSetNumPlayers(DefaultNumPlayersOneTeam, setFieldValue);
    }
    setFieldValue("numTeams", newTeamsValue);
  }

  function handleSetNumPlayers(num, setFieldValue) {
    setFieldValue("numPlayers", num);
  }

  function handleAddAccountNeeded(accountsNeeded, newtype, setFieldValue) {
    if (!newtype) return;
    if (!accountsNeeded) accountsNeeded = [];
    const newAccountsNeeded = [...accountsNeeded, newtype];
    setFieldValue("accountsNeeded", newAccountsNeeded);
    setAccountToBeAdded("");
  }
  function handleRemoveAccountNeeded(
    accountsNeeded,
    removetype,
    setFieldValue
  ) {
    const newAccountsNeeded = accountsNeeded.filter(
      (elem) => elem !== removetype
    );
    setFieldValue("accountsNeeded", newAccountsNeeded);
  }

  function createSelectedUsers(numTeams, numPlayers) {
    let ret = [];
    const emptyUser = {
      userId: "",
      displayName: "",
    };

    for (let ti = 0; ti < numTeams; ti++) {
      ret[ti] = [];
      for (let pi = 0; pi < (numPlayers + 1) / numTeams; pi++) {
        ret[ti][pi] = emptyUser;
      }
    }
    let currentUser = { ...currentUserProfile };
    delete currentUser.lastCreatedLobbySettings;

    ret[0][0] = currentUser;
    ret[0][0]["userId"] = currentUser["id"];

    return ret;
  }

  // if (!streamerProfile) return <LoadingComponent content='Loading lobby ...' />;
  if (loading) return <LoadingComponent content='Loading lobby ...' />;
  if (error) return <Navigate to='/error' />;

  return (
    <Vcontainer>
      <Section
        title={selectedLobby ? "Edit Lobby" : "Create Lobby"}
        minHeight={"1090px"}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            if (values.accountsNeeded === undefined)
              values = { ...values, accountsNeeded: null };

            try {
              values.selectedUsers = createSelectedUsers(
                values.numTeams,
                values.numPlayers
              );
              values.dontCreateDiscordChannels =
                values.dontCreateDiscordChannels
                  ? values.dontCreateDiscordChannels
                  : false;

              values.twitchChannel = values.twitchChannel
                ? values.twitchChannel
                : "";
              values.twitchSubscribersOnly = values.twitchSubscribersOnly
                ? values.twitchSubscribersOnly
                : false;

              if (selectedLobby) {
                if (selectedLobby.status === "all_selected") {
                  values.status = "in_progress";
                  deleteDiscordChannelViaFirestore(lobbyId);
                }

                await updateLobbyInFirebase(lobbyId, values).then(
                  (response) => {
                    setLastCreatedLobbySettingsToProfile(values).then(() => {
                      actions.setSubmitting(false);
                      navigate(`/${currentUserProfile.url}`);
                    });
                  }
                );

                await updateLobbylogInFirestore(
                  lobbyId,
                  currentUserProfile.id,
                  {
                    streamerUrl: currentUserProfile.url,
                    ...(values.status && { status: values.status }),
                  }
                );
              } else {
                values = {
                  ...values,
                  maxQueueSize: currentUserProfile.maxQueueSize
                    ? currentUserProfile.maxQueueSize
                    : null,
                };

                await addLobbyToFirebase(values).then((response) => {
                  const newLobbyId = response.key;
                  createLobbylogInFirestore(newLobbyId, {
                    streamerUrl: currentUserProfile.url,
                    streamerUid: currentUserProfile.id,
                    status: "open",
                  });

                  setCreatedLobbyProfile(newLobbyId).then(() => {
                    setLastCreatedLobbySettingsToProfile(values).then(() => {
                      actions.setSubmitting(false);
                      navigate(`/${currentUserProfile.url}`);
                    });
                  });
                });
              }
            } catch (error) {
              console.log(error);
              toast.error(error.message);
              actions.setSubmitting(false);
            } finally {
            }
          }}
        >
          {({ isSubmitting, isValid, setFieldValue, values }) => (
            <Box maxW='4xl' pl='20%' mt='40px'>
              <Form>
                <Heading variant='formsection'>Lobby Details</Heading>
                <MyTextInput name='game' label='Game' />
                <MyTextArea name='description' label='Description' rows='3' />

                {/* <MySelectInput
                  name='category'
                  label='Category'
                  options={categoryData}
                  value={values.category}
                /> */}

                <Heading mt='20px' variant='formsection'>
                  Team Size
                </Heading>
                <HStack ml='0px' display='flex' mt='40px' position='relative'>
                  <Box
                    ml='0px'
                    textAlign='left'
                    opacity={values.numTeams === 1 ? 1 : 0.2}
                  >
                    In One Team
                  </Box>
                  <Box>
                    <Switch
                      id='numTeams'
                      isChecked={values.numTeams === 2}
                      _focus={{ boxShadow: "none" }}
                      onChange={(e) => {
                        handleToggleNumTeams(values.numTeams, setFieldValue);
                      }}
                      colorScheme='grey.100'
                      size='lg'
                    />
                  </Box>
                  <Box
                    textAlign='left'
                    opacity={values.numTeams === 2 ? 1 : 0.2}
                  >
                    In Two Teams
                  </Box>
                  <FormLabel left='-2' top='-8'>
                    How many players would you like to search?
                  </FormLabel>
                </HStack>

                <Box mt='10px' mb='3' position='relative'>
                  {/* <FormLabel top='-10'>
                    How many players would you like to search?
                  </FormLabel> */}
                  <HStack>
                    <Box>You + </Box>
                    {teamSizes[values.numTeams - 1].map((n) => (
                      <Button
                        name={`${n}_players`}
                        variant={
                          n.value === values.numPlayers ? "primary" : "solid"
                        }
                        onClick={(e) => {
                          handleSetNumPlayers(n.value, setFieldValue);
                        }}
                        key={n.value}
                        mr='1px'
                        size='md'
                      >
                        {n.text}
                      </Button>
                    ))}
                  </HStack>
                </Box>

                <Box mt='30px'>
                  <Heading variant='formsection'>
                    Join Restriction: Twitch subscribers
                  </Heading>
                  <Box mt='20px' spacing={4}>
                    <Box>
                      <Checkbox
                        name='twitchSubscribersOnly'
                        id='twitchSubscribersOnly'
                        _focus={{ boxShadow: "none" }}
                        isChecked={values.twitchSubscribersOnly}
                        onChange={(e) =>
                          setFieldValue(
                            "twitchSubscribersOnly",
                            e.target.checked
                          )
                        }
                      >
                        Allow only subscribers to my Twitch Channel
                      </Checkbox>
                    </Box>
                    {values.twitchSubscribersOnly && (
                      <Box>
                        <HStack display='flex'>
                          <Box flex='160px 0 0'>
                            <Text fontSize='lg' mt='17px' textAlign='right  '>
                              www.twitch.tv/
                            </Text>
                          </Box>
                          <Box flex='1'>
                            <MyTextInput
                              name='twitchChannel'
                              label='My Twitch Channel'
                              labelOffset='-145px'
                            />
                          </Box>
                        </HStack>
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box mt='30px'>
                  <Heading mt={"0px"} variant='formsection'>
                    Join Restriction: Accounts
                  </Heading>
                  <HStack mt='20px' spacing={4}>
                    {values.accountsNeeded?.length === 0 && (
                      <Text>
                        <AiOutlineWarning
                          style={{
                            color: "red",
                            fontSize: "46px",
                            float: "left",
                            marginRight: "10px",
                          }}
                        />{" "}
                        You probably need at least one account, so you can
                        contact/invite/communicate with your selected users upon
                        playing.
                      </Text>
                    )}
                    {values.accountsNeeded?.map((type) => (
                      <Tag
                        size='md'
                        key={type}
                        borderRadius='full'
                        variant='solid'
                        colorScheme='green'
                      >
                        <TagLabel>
                          {accountTypes
                            .filter((a) => a.key === type)
                            .map((a) => a.name)}
                        </TagLabel>
                        <TagCloseButton
                          onClick={() =>
                            handleRemoveAccountNeeded(
                              values.accountsNeeded,
                              type,
                              setFieldValue
                            )
                          }
                        />
                      </Tag>
                    ))}
                  </HStack>

                  <HStack mt='20px'>
                    <Select
                      mt='3px'
                      mb='3'
                      variant='flushed'
                      // size='sm'
                      width='200px'
                      onChange={(e, value) => {
                        setAccountToBeAdded(e.target.value);
                      }}
                    >
                      {[
                        { name: "(select account type)", key: "" },
                        ...accountTypes,
                      ]
                        .filter((a) => !values.accountsNeeded?.includes(a.key))
                        .map((option) => (
                          <option value={option.key} key={option.key}>
                            {option.name}
                          </option>
                        ))}
                    </Select>
                    <Button
                      size='sm'
                      // mt='15px'
                      onClick={() =>
                        handleAddAccountNeeded(
                          values.accountsNeeded,
                          accountToBeAdded,
                          setFieldValue
                        )
                      }
                    >
                      Add
                    </Button>
                  </HStack>
                  {values.accountsNeeded?.filter((a) => a === "discord")
                    .length === 0 && (
                    <input
                      type='hidden'
                      name='dontCreateDiscordChannels'
                      value='true'
                    />
                  )}
                  {values.accountsNeeded?.filter((a) => a === "discord")
                    .length > 0 && (
                    <Box>
                      <Checkbox
                        name='dontCreateDiscordChannels'
                        id='dontCreateDiscordChannels'
                        _focus={{ boxShadow: "none" }}
                        isChecked={values.dontCreateDiscordChannels}
                        onChange={
                          (e) =>
                            setFieldValue(
                              "dontCreateDiscordChannels",
                              e.target.checked
                            )
                          // console.log(e.target.checked)
                        }
                      >
                        Don't create Discord channels, I want to use my own
                        discord server
                      </Checkbox>
                    </Box>
                  )}

                  {values.accountsNeeded?.filter((a) => a === "discord")
                    .length > 0 &&
                    !currentUserProfile?.accountsKeys?.includes("discord") && (
                      <Box
                        mt={"20px"}
                        p={"30px"}
                        border={"1px solid"}
                        borderColor={"primary"}
                      >
                        You need to add your own Discord Account before adding
                        Discord as Join Restriction.
                        <Box mt={"20px"}>
                          <Button
                            leftIcon={<MdAddBox />}
                            mx={"1px"}
                            onClick={() => {
                              setLastCreatedLobbySettingsToProfile(values).then(
                                () => {
                                  dispatch(
                                    openModal({
                                      modalType: "AccountForm",
                                      modalProps: {
                                        accountType: "discord",
                                        accountTypes: accountTypes,
                                        currentUserProfile: currentUserProfile,
                                        handleClose: handleCloseAccountForm,
                                      },
                                    })
                                  );
                                }
                              );
                            }}
                            variant='primary'
                          >
                            Add Discord Account
                          </Button>
                        </Box>
                      </Box>
                    )}
                </Box>

                <ButtonGroup mb='12' h='80px' mt='10' w='full' display='block'>
                  <Button
                    isLoading={isSubmitting}
                    isDisabled={
                      !isValid ||
                      isSubmitting ||
                      (values.accountsNeeded?.filter((a) => a === "discord")
                        .length > 0 &&
                        !currentUserProfile?.accountsKeys?.includes("discord"))
                    }
                    variant='primary'
                    type='submit'
                    float='left'
                  >
                    Submit
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    as={Link}
                    to={`/${currentUserProfile.url}`}
                    type='submit'
                    float='left'
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </Form>
            </Box>
          )}
        </Formik>
      </Section>
    </Vcontainer>
  );
}
