import React from "react";
import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";

import LogoHorizontal from "../components/Logo/LogoHorizontal";
import { useSelector } from "react-redux";

export default function Footer() {
  const ListHeader = ({ children }: { children: ReactNode }) => {
    return (
      <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
        {children}
      </Text>
    );
  };
  const { currentUser } = useSelector((state) => state.auth);

  function getYear() {
    return new Date().getFullYear();
  }
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
      pb='50px'
      // bg='rgba(0,0,0,0.3)'
    >
      <Container as={Stack} maxW={"container.xl"} py={10}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 1fr 1fr" }}
          spacing={8}
        >
          <Stack spacing={6}>
            <LogoHorizontal
              margin='0px'
              height='50px'
              m='0px'
              display='inline-block'
              maxH='54px'
              maxW='250px'
            />
            <Box fontSize={"xs"} opacity='0.5'>
              © {getYear()} HexFourB Interactive GmbH
              <Box>All rights reserved.</Box>
            </Box>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>For Streamers</ListHeader>
            <Link as={ReactLink} to='/'>
              Overview
            </Link>
            <Link as={ReactLink} to='/about/streamerfeatures'>
              Features
            </Link>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>For Viewers</ListHeader>
            {/* <Link as={ReactLink} to='/help/viewerhelp'>
              Help
            </Link>
            <Link as={ReactLink} to='/about/viewerfeatures'>
              About
            </Link> */}
            {!currentUser?.uid && (
              <Link as={ReactLink} to='/login'>
                Login / Register
              </Link>
            )}
            {/* {currentUser?.uid && (
              <Link as={ReactLink} to='/logout'>
                Logout
              </Link>
            )} */}
            <Link as={ReactLink} to='/about/donation'>
              Donate
            </Link>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Legal</ListHeader>
            <Link as={ReactLink} to='/imprint'>
              Imprint
            </Link>
            <Link as={ReactLink} to='/terms-of-use'>
              Terms of Service
            </Link>
            <Link href={"/privacy-policy"}>Privacy Policy</Link>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Contact</ListHeader>
            <Link target='_blank' href={"https://discord.gg/7SKpGk9fDw"}>
              Discord
            </Link>
            <Link target='_blank' href={"https://twitter.com/streamteamgg"}>
              Twitter
            </Link>
            <Link as={ReactLink} to='/about/email'>
              eMail
            </Link>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
