// v9 compat packages are API compatible with v8 code
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/functions";

// let firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: "streamteam-dev.firebaseapp.com",
//   databaseURL:
//     "https://streamteam-dev-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "streamteam-dev",
//   storageBucket: "streamteam-dev.appspot.com",
//   messagingSenderId: "368607496818",
//   appId: "1:368607496818:web:23e0a3425e2ea0cc54d0eb",
// };

const firebaseConfig = {
  apiKey: "AIzaSyCdM40N9EOIhWUVRkqcmx4rUuVhnsl58HU",
  // authDomain: "streamteam-prod.firebaseapp.com",
  authDomain: "www.streamteam.gg",
  databaseURL:
    "https://streamteam-prod-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "streamteam-prod",
  storageBucket: "streamteam-prod.appspot.com",
  messagingSenderId: "172945725996",
  appId: "1:172945725996:web:8933e7302be7eecf398f89",
  measurementId: "G-516HB3JY42",
};

if (
  window.location.hostname === "localhost" &&
  process.env.REACT_APP_USE_EMULATOR === "true"
) {
  firebaseConfig.databaseURL =
    "http://localhost:9001/?ns=streamteam-prod-default-rtdb";
  firebaseConfig.storageBucket = "localhost:9199";
  firebaseConfig.experimentalAutoDetectLongPolling = true;
}

// NOTE: do NOT put this in production.
// firebase.firestore().settings({  });

firebase.initializeApp(firebaseConfig);
firebase.firestore();

if (
  window.location.hostname === "localhost" &&
  process.env.REACT_APP_USE_EMULATOR === "true"
) {
  firebase.auth().useEmulator("http://localhost:9099");
}

export default firebase;
