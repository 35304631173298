import React, { useState } from "react";
import { removeUserAccountToFirestore } from "../../../app/firestore/firestoreService";
import { AiTwotoneEdit } from "react-icons/ai";
import { Box, Grid, Heading, Link, Text } from "@chakra-ui/layout";
import { BsTrash } from "react-icons/bs";
import AccountFormEdit from "./AccountFormEdit";
import AccountFormAdd from "./AccountFormAdd";
import { accountTypes } from "../../../app/api/accounttypes";

import { Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function SettingsAccountsTab({ action, currentUserProfile }) {
  const [showEditAccount, setShowEditAccount] = useState();
  const navigate = useNavigate();

  function handleDelete(type) {
    removeUserAccountToFirestore(currentUserProfile, type);
    if (navigate) {
      navigate("/settings/accounts");
    }
  }

  if (currentUserProfile === undefined) return <></>;

  return (
    <Box>
      <Heading mb='5' variant='formsection'>
        Your Accounts
      </Heading>
      <Box mb='50px'>
        {currentUserProfile.accounts?.filter((a) => a.type === "discord")
          .length === 0 &&
          action === "discordadded" && <Spinner />}
        {(!currentUserProfile.accounts ||
          currentUserProfile.accounts?.length === 0) && (
          <Text opacity='0.3' fontStyle='italic'>
            No Account added yet.
          </Text>
        )}
        {currentUserProfile.accounts &&
          currentUserProfile.accounts
            .sort((a, b) => {
              return a.type <= b.type ? -1 : 1;
            })
            .map((account) => (
              <Grid
                p='0px'
                mb='5px'
                key={account.type}
                maxW='800px'
                templateColumns='repeat(2, 1fr)'
                gap='2px'
              >
                <Box w='100%' bg='rgba(255,255,255,0.2)' p='5px'>
                  {accountTypes
                    .filter((a) => a.key === account.type)
                    .map((a) => a.name)}
                </Box>
                <Box
                  borderLeft='3px solid'
                  borderColor='primary'
                  position='relative'
                  w='100%'
                  pl='10px'
                  h='36px'
                  bg='rgba(255,255,255,0.1)'
                >
                  {showEditAccount === account.type && (
                    <AccountFormEdit
                      currentUserProfile={currentUserProfile}
                      account={account}
                      accountTypes={accountTypes}
                      closeEdit={setShowEditAccount}
                    />
                  )}
                  {showEditAccount !== account.type && (
                    <>
                      <Box mt='5px' mb='4px'>
                        {account.value}
                        <Link
                          onClick={() => {
                            setShowEditAccount(account.type);
                          }}
                        >
                          {account.type !== "discord" && (
                            <AiTwotoneEdit style={{ display: "inline" }} />
                          )}
                        </Link>
                      </Box>
                      <Box
                        position='absolute'
                        fontSize='sm'
                        right='5px'
                        top='10px'
                      >
                        <BsTrash
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDelete(account.type)}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
            ))}
      </Box>
      <Heading mb='5' variant='formsection'>
        Add New
      </Heading>
      <AccountFormAdd
        accountTypes={accountTypes}
        currentUserProfile={currentUserProfile}
      />
    </Box>
  );
}
