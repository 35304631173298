import React from "react";
import { useSelector } from "react-redux";
import { ImTwitch } from "react-icons/im";
import { socialLogin } from "../../app/firestore/firebaseService";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ButtonGroup,
  Box,
  VStack,
  StackDivider,
} from "@chakra-ui/react";

import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function UnauthModal({ setModalOpen }) {
  const navigate = useNavigate();
  const { prevLocation } = useSelector((state) => state.auth);
  const laststreamerUrl = localStorage.getItem("laststreamerUrl");

  function handleClose() {
    if (!navigate) {
      setModalOpen(false);
      return;
    }
    if (prevLocation) {
      navigate(prevLocation);
    } else {
      navigate(laststreamerUrl ? laststreamerUrl : "/");
    }
    if (typeof setModalOpen === "function") setModalOpen(false);
  }

  function handleOpenLoginModal(targetURL) {
    navigate(targetURL);
  }

  return (
    <Modal
      // isCentered
      size='lg'
      isOpen={true}
      onClose={handleClose}
    >
      <ModalOverlay bg='none' backdropFilter='auto' backdropBlur='20px' />
      <ModalContent p={[0, 0]} borderRadius='20px' mt={[50, 50]}>
        <ModalHeader>Login</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={[1, 5]}>
          <VStack>
            {/* <p>Please either login or register to see this content</p> */}

            <ButtonGroup>
              <Button
                w={200}
                variant='primary'
                onClick={() => handleOpenLoginModal("/login")}
              >
                Login
              </Button>
              <Button w={200} onClick={() => handleOpenLoginModal("/register")}>
                Register
              </Button>
            </ButtonGroup>
            <StackDivider p='30' />
            <Button
              onClick={() => socialLogin("twitch")}
              variant='twitch'
              leftIcon={<ImTwitch />}
            >
              Login with Twitch
            </Button>
            <StackDivider p='30' />
            <Box style={{ textAlign: "center" }}>
              <Button onClick={handleClose}>Cancel</Button>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>

    // <Modal open={open} size='mini' onClose={handleClose}>
    //   <Modal.Header content='You need to be signed in to do that' />
    //   <Modal.Content>
    //     <p>Please either login or register to see this content</p>
    //     <Button.Group widths={4}>
    //       <Button
    //         fluid
    //         color='teal'
    //         content='Login'
    //         onClick={() => handleOpenLoginModal("LoginForm")}
    //       />
    //       <Button.Or />
    //       <Button
    //         fluid
    //         color='green'
    //         content='Register'
    //         onClick={() => handleOpenLoginModal("RegisterForm")}
    //       />
    //     </Button.Group>
    //     <Divider />
    //     <div style={{ textAlign: "center" }}>
    //       <p>Or click Cancel to continue as a guest</p>
    //       <Button onClick={handleClose} content='Cancel' />
    //     </div>
    //   </Modal.Content>
    // </Modal>
  );
}
