import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useToast } from "@chakra-ui/toast";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncActionFinish,
  asyncActionStart,
} from "../../../app/async/asyncReducer";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { Form, Formik } from "formik";
import { FormControl } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/react";
import { Box, Flex, HStack } from "@chakra-ui/layout";
import { listenToQuizitemsFromFirestore } from "../../../app/firestore/firestoreService";
import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import { Checkbox, Wrap, WrapItem } from "@chakra-ui/react";
import { createLobbyQuizInFirebase } from "../../../app/firestore/firebaseService";

export default function QuizCreateForm({ lobbyId }) {
  const { currentUserProfile } = useSelector((state) => state.profile);
  const [allQuizitems, setAllQuizitems] = useState(undefined);
  const [quizitems, setQuizitems] = useState(undefined);
  const toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    const query = listenToQuizitemsFromFirestore(currentUserProfile.id);

    query.onSnapshot((querySnapshot) => {
      let items = [];
      querySnapshot.forEach((doc) => {
        let item = {
          id: doc.id,
          ...doc.data(),
        };
        items.push(item);
      });
      setAllQuizitems(items);
    });
  }, [currentUserProfile.id]);

  function handleCancel() {
    dispatch(closeModal());
  }

  if (!allQuizitems) return <></>;
  const numAvailableQuesions = quizitems?.length ? quizitems.length : 0;

  let dummy = [];
  if (allQuizitems?.length) {
    allQuizitems.forEach((elem) => {
      if (elem.category) dummy[elem.category] = elem.category;
    });
  }
  const allCategories = [];
  // categories.push({ key: "all", text: "All", value: "all" });
  Object.keys(dummy).forEach(function (key) {
    allCategories.push({ key: key, text: key, value: key });
  });

  function calcQuizItems(c, newCat = undefined) {
    const nc = [];
    if (newCat) nc[newCat] = true;
    const categories = {
      ...c,
      ...nc,
    };

    const catQuizItems = [...allQuizitems];
    const selectedQuizItems = [];
    for (var i = 0; i < catQuizItems.length; i++) {
      if (categories && categories[catQuizItems[i]["category"]]) {
        selectedQuizItems.push(catQuizItems[i]);
      }
    }
    setQuizitems(selectedQuizItems);
    return selectedQuizItems;
  }

  function handleCommitAction(numQuestions, categories) {
    // console.log(categories);
    const filteredQuizItems = calcQuizItems(categories);
    let selectedQuizItems = [...filteredQuizItems];

    if (selectedQuizItems.length === 0) {
      throw new Error("You need to select at least one category.");
    }

    for (var i = selectedQuizItems.length - 1; i >= numQuestions; i--) {
      selectedQuizItems.splice(
        Math.floor(Math.random() * selectedQuizItems.length),
        1
      );
    }
    selectedQuizItems = selectedQuizItems.sort(() => Math.random() - 0.5);

    createLobbyQuizInFirebase(lobbyId, selectedQuizItems);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        numQuestions: 5,
      }}
      validationSchema={Yup.object({
        numQuestions: Yup.number().required(),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          dispatch(asyncActionStart());
          handleCommitAction(values.numQuestions, values.categories);
          dispatch(asyncActionFinish());
          toast({
            title: "Success",
            description: "Quiz has been prepared and is ready to launch!",
            status: "success",
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            isClosable: true,
          });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values, isValid, dirty, setFieldValue }) => (
        <Form key='quiz_item_edit'>
          <FormControl>
            <Box color='white'>How many questions would you like to play?</Box>
            <Flex>
              <Box flex='0 0 30px' textAlign='left' fontSize='xl'>
                {values.numQuestions}
              </Box>
              <Slider
                defaultValue={values.numQuestions}
                min={1}
                max={10}
                step={1}
                onChange={(val) => setFieldValue("numQuestions", val)}
              >
                <SliderTrack bg='gray.500'>
                  <Box position='relative' right={10} />
                  <SliderFilledTrack bg='primary' />
                </SliderTrack>
                <SliderThumb boxSize={6} />
              </Slider>
            </Flex>
            <Box mt='30px'>
              <Box color='white'>Which categories do you want to play?</Box>
              <Wrap spacing={"30px"}>
                {allCategories.map((cat) => (
                  <WrapItem key={cat.key}>
                    <Checkbox
                      name={allCategories[cat.key]}
                      id={allCategories[cat.key]}
                      onChange={(e) => {
                        setFieldValue(
                          `categories[${cat.key}]`,
                          e.target.checked
                        );
                        calcQuizItems(values.categories, cat.key);
                      }}
                    >
                      {cat.text}
                    </Checkbox>
                  </WrapItem>
                ))}
              </Wrap>
              <Box mt='10px' opacity={"0.5"} fontSize={"sm"}>
                {numAvailableQuesions} questions total in selected categories.
              </Box>
            </Box>

            <HStack mt='40px'>
              <Button
                isLoading={isSubmitting}
                isDisabled={isSubmitting || !isValid}
                type='submit'
                variant='primary'
              >
                Start
              </Button>
              <Button onClick={() => handleCancel()}>Cancel</Button>
            </HStack>
          </FormControl>
        </Form>
      )}
    </Formik>
  );
}
