import { Badge, Box, Button, Select, Wrap, WrapItem } from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeModal } from "../../../app/common/modals/modalReducer";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import { countOpenSlots } from "../../../app/common/util/util";
import { updateLobbyUsersBulkStatusInFirebase } from "../../../app/firestore/firebaseService";

import {
  setLobbyGameInProgress,
  setSelectedInLobby,
  // setSelectedInLobby,
} from "../common/SelectorHelpers";
import { sortAndCutTypes } from "./sortAndCutTypes.js";
import UserItem from "./UserItem";

export function TypeSelectInput({ label, options, setFunction, ...props }) {
  // const [field, meta, helpers] = useField(props);

  return (
    <Box position='relative'>
      <Select
        mt='-7px'
        // mb='3'
        // isInvalid={meta.touched && !!meta.error}
        variant='flushed'
        size='md'
        color={"primary"}
        fontWeight='bold'
        // width='250px'
        // value={field.value || ""}
        onChange={(e) => {
          setFunction(e.target.value);
          // helpers.setValue(e.target.value);
          // setSelectedAccountType(e.target.value);
        }}
        // onBlur={() => helpers.setTouched(true)}
        {...props}
      >
        {options.map((option) => (
          <option value={option.key} key={option.key}>
            {option.name}
          </option>
        ))}
      </Select>

      <Badge
        marginTop='0'
        position='absolute'
        bottom='-10px'
        left='0'
        colorScheme='red'
      >
        {/* {meta.touched && meta.error ? meta.error : null} */}
      </Badge>
    </Box>
  );
}

export default function SortAndCut() {
  const { selectedLobby } = useSelector((state) => state.lobby);
  const dispatch = useDispatch();
  const [users, setUsers] = useState(undefined);
  const [selectedType, setSelectedType] = useState("numNotSelectedStreak");
  const [selectedOrder, setSelectedOrder] = useState("1");
  const [selectedCut, setSelectedCut] = useState("50percent");
  const [usersReveiled, setUsersReveiled] = useState(false);
  const numOpenSlots = countOpenSlots(selectedLobby);

  const usersSorted = users?.sort((a, b) =>
    b.stats[selectedType] > a.stats[selectedType]
      ? 1 * selectedOrder
      : -1 * selectedOrder
  );

  let numNotCutted = 0;
  switch (selectedCut) {
    case "66percent":
      numNotCutted = Math.ceil(users?.length / 3);
      break;
    case "50percent":
      numNotCutted = Math.ceil(users?.length / 2);
      break;
    case "33percent":
      numNotCutted = Math.ceil((users?.length / 3) * 2);
      break;
    case "allbutopenslots":
      numNotCutted = numOpenSlots;
      break;
    default:
      numNotCutted = 1;
  }

  if (numNotCutted < numOpenSlots) {
    numNotCutted = numOpenSlots;
  }

  useEffect(() => {
    setLobbyGameInProgress(selectedLobby.lobbyId, "SortAndCut");

    return () => {
      setLobbyGameInProgress(selectedLobby.lobbyId, "");
    };
  }, [selectedLobby.lobbyId, selectedLobby.streamerId, users]);

  // Initialize
  if (!users && selectedLobby.users) {
    const availableUsers = [...selectedLobby.users].filter(
      (sel) =>
        sel.status !== "not_selected" &&
        sel.status !== "removed" &&
        sel.status !== "selected"
    );

    setUsers(availableUsers);
  }

  function handleAction() {
    if (usersReveiled) {
      dispatch(closeModal());
      const notSelectedUsers = usersSorted.slice(numNotCutted, users.length);
      const selectedUsers = usersSorted.slice(0, numNotCutted);
      if (numNotCutted <= numOpenSlots) {
        setSelectedInLobby(selectedLobby, selectedUsers);
      } else {
        updateLobbyUsersBulkStatusInFirebase(
          selectedLobby.lobbyId,
          notSelectedUsers,
          "not_selected"
        );
      }
      setLobbyGameInProgress(selectedLobby.lobbyId, "");
    } else {
      setUsersReveiled(true);
    }
  }

  return (
    <ModalWrapper size='6xl' header='Sort & Cut'>
      <Box m='auto' textAlign={"center"}>
        <Wrap justify='center'>
          <WrapItem>Sort users by </WrapItem>
          <WrapItem>
            <TypeSelectInput
              name='type'
              label='Type'
              disabled={usersReveiled}
              setFunction={setSelectedType}
              value={selectedType}
              options={[...sortAndCutTypes]}
            />
          </WrapItem>
          <WrapItem>in</WrapItem>
          <WrapItem>
            <WrapItem>
              <TypeSelectInput
                name='type'
                label='Type'
                setFunction={setSelectedOrder}
                value={selectedOrder}
                disabled={usersReveiled}
                options={[
                  { name: "descending", key: "1" },
                  { name: "ascending", key: "-1" },
                ]}
              />
            </WrapItem>
          </WrapItem>
          <WrapItem>order and cut of</WrapItem>
          <WrapItem>
            <TypeSelectInput
              name='type'
              label='Type'
              setFunction={setSelectedCut}
              value={selectedCut}
              disabled={usersReveiled}
              options={[
                { name: "bottom 66%", key: "66percent" },
                { name: "bottom 50%", key: "50percent" },
                { name: "bottom 33%", key: "33percent" },
                { name: "all but needed", key: "allbutopenslots" },
              ]}
            />
          </WrapItem>
          {/* <WrapItem>.</WrapItem> */}
        </Wrap>

        <Button
          variant='primary'
          width='250px'
          mt='30px'
          id='execute_cut'
          onClick={() => handleAction()}
          disabled={!selectedType}
        >
          {usersReveiled
            ? `Finalize`
            : `Proceed with cut (${users?.length - numNotCutted} user${
                users?.length - numNotCutted > 1 ? "s" : ""
              })`}
        </Button>
      </Box>

      <Wrap mt={"40px"}>
        {usersSorted &&
          usersSorted
            .slice(0, numNotCutted)
            .map((user, index) => (
              <UserItem
                usersReveiled={usersReveiled}
                user={user}
                selectedType={selectedType}
              />
            ))}
      </Wrap>
      <Box
        borderBottom={"2px"}
        borderStyle={"dashed"}
        borderColor={"primary"}
        mt={"10px"}
        mb={"10px"}
      />
      <Wrap opacity={"0.5"}>
        {usersSorted &&
          usersSorted
            .slice(numNotCutted, users.length)
            .map((user, index) => (
              <UserItem
                usersReveiled={usersReveiled}
                user={user}
                selectedType={selectedType}
              />
            ))}
      </Wrap>
    </ModalWrapper>
  );
}
